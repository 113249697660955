import { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Images } from '@/theme/Images'
import { useDynamicFontSize } from '@/hooks'

const CreditCard = ({ data, masked, blurred, width, height }) => {
  const cardRef = useRef(null)
  const fontSize = useDynamicFontSize(cardRef, 0.05)

  //Memoizing the card number according to current state
  const cardNumber = useMemo(() => {
    if (masked) {
      return `•••• •••• •••• ${data?.cardNumber?.slice(-4)}`
    } else {
      return data?.cardNumber
    }
  }, [data?.cardNumber, masked])

  return (
    <div
      className={`w-full h-auto relative inline-block ${blurred ? 'blur' : ''}`}
      data-testid="credit-card-mockup-container"
    >
      <img src={Images.creditCardMockupNew} alt="credit card mockup" ref={cardRef} width={width} height={height} />
      <span
        className="absolute top-[65%] left-[6%] text-white font-semibold font-manrope"
        style={{ fontSize: fontSize - 1 }}
      >
        {data?.cardHolderName?.toUpperCase()}
      </span>
      {!masked ? (
        <>
          <span
            className="absolute top-[65%] left-[60%] text-white font-semibold font-manrope"
            style={{ fontSize: fontSize - 1 }}
          >
            {data?.expDate}
          </span>
          <span
            className="absolute top-[65%] left-[87%] text-white font-semibold font-manrope"
            style={{ fontSize: fontSize - 1 }}
          >
            {data?.cvv}
          </span>
        </>
      ) : null}
      <span
        className="absolute top-[78%] left-[6%] text-white font-semibold font-manrope"
        style={{ fontSize: fontSize + 2 }}
      >
        {cardNumber}
      </span>
    </div>
  )
}
CreditCard.propTypes = {
  data: PropTypes.object,
  masked: PropTypes.bool,
  blurred: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
}
export default CreditCard
