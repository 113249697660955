import { CARD_STATUS_ACTIVATION_REQUIRED, CARD_STATUS_CLOSED } from '@/data/constants'

export class User {
  constructor(user) {
    // Directly assign user properties to the instance
    Object.assign(this, user)
  }

  getCardDetails() {
    if (!this.customer?.consumerCard || this.customer.consumerCard[0] === null) {
      return null
    }
    return this.customer.consumerCard.find(({ cardType }) => cardType === 'PRIMARY_REISSUED') || null
  }

  //NOTE: This can return either null or two cards i.e: ACTIVATION_REQUIRED(=p-card) and ACTIVE(=v-card) cards
  getActivationRequiredCardDetails() {
    if (!this.customer?.consumerCard || this.customer.consumerCard[0] === null) {
      return null
    }
    const cards = this.customer.consumerCard

    const isActivationRequired = cards.some(({ status }) => status === CARD_STATUS_ACTIVATION_REQUIRED)

    if (isActivationRequired) {
      return cards
    } else {
      return null
    }
  }

  getLockUnlockCardDetails() {
    if (!this.customer?.consumerCard || this.customer.consumerCard[0] === null) {
      return null
    }

    return (
      this.customer.consumerCard.filter(
        ({ status }) => status !== CARD_STATUS_CLOSED && status !== CARD_STATUS_ACTIVATION_REQUIRED
      )[0] || null
    )
  }

  isBannerDismissed() {
    if (!this.customer || !this.customer.preferences) {
      return false
    }
    return this.customer.preferences.statusBannerDismissed
  }

  isFirstLoginModalDismissed() {
    if (!this.customer || !this.customer.preferences) {
      return false
    }
    return !!this.customer.preferences.firstLoginModalDismissed
  }

  getCustomer() {
    return this.customer
  }

  hasCustomer() {
    return !!this.customer
  }
}
