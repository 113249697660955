import PropTypes from 'prop-types'
import { Avatar, AvatarFallback, AvatarImage } from '@/components/ui/avatar'

const AvatarWithFallback = ({ source, fallback, className }) => {
  return (
    <Avatar className={className}>
      <AvatarImage src={source} alt="avatar" />
      <AvatarFallback className="bg-dGray-100 border-[1px] border-dGray-200">{fallback}</AvatarFallback>
    </Avatar>
  )
}
AvatarWithFallback.propTypes = {
  source: PropTypes.string,
  fallback: PropTypes.string,
  className: PropTypes.string,
}
export default AvatarWithFallback
