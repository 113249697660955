import * as Yup from 'yup'

const today = new Date()
const currentMonth = today.getMonth() + 1
const currentYear = today.getFullYear() % 100

export const emailValidation = Yup.string()
  .trim()
  .matches(/[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/, 'Invalid email address')
  .required('Email is required')

export const passwordValidation = Yup.string().required('Password is required')

export const confirmPasswordValidation = Yup.string()
  .required('Confirm password is required')
  .oneOf([Yup.ref('password'), null], 'Passwords must match')

export const cardLastFourDigits = Yup.string()
  .required('Last four digits of card required')
  .matches(/^\d{4}$/, 'Invalid 4-digit number')
export const cardExpirationDate = Yup.string()
  .required('Expiration date required')
  .matches(/^(0[1-9]|1[0-2])\/\d{2}$/, 'Invalid expiration date format')
  .test('expirationDate', 'Invalid expiration date', value => {
    if (!value) return false
    const [month, year] = value.split('/').map(Number)
    return year > currentYear || (year === currentYear && month > currentMonth)
  })

export const cardCvv = Yup.string()
  .required('CVV required')
  .matches(/^\d{3,4}$/, 'Invalid CVV')
export const verificationCode = length => Yup.string().required().length(length)

export const cardCvcValidation = Yup.string()
  .required('CVC required')
  .matches(/^\d{3}$/, 'Invalid CVC. Enter only 3 digits.')

export const checkCurrentAndNewPassword = Yup.string()
  .required('New password is required')
  .test('not-same-as-current', 'New password cannot be the same as the current password', (value, context) => {
    const { currentPassword } = context.parent
    return value !== currentPassword
  })
