import PropTypes from 'prop-types'

function UserCircleAvatarIcon({ stroke = '#5A8090', width = '35px', height = '40px' }) {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" width={width} height={height} viewBox="10 10 20 20" fill="none">
      <g clipPath="url(#clip0_415_102811)">
        <path
          d="M14.4296 26.1987C14.9365 25.0043 16.1201 24.1667 17.4993 24.1667H22.4993C23.8786 24.1667 25.0622 25.0043 25.5691 26.1987M23.3327 17.9167C23.3327 19.7576 21.8403 21.25 19.9993 21.25C18.1584 21.25 16.666 19.7576 16.666 17.9167C16.666 16.0757 18.1584 14.5833 19.9993 14.5833C21.8403 14.5833 23.3327 16.0757 23.3327 17.9167ZM28.3327 20C28.3327 24.6024 24.6017 28.3333 19.9993 28.3333C15.397 28.3333 11.666 24.6024 11.666 20C11.666 15.3976 15.397 11.6667 19.9993 11.6667C24.6017 11.6667 28.3327 15.3976 28.3327 20Z"
          stroke={stroke}
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </g>
    </svg>
  )
}

UserCircleAvatarIcon.propTypes = {
  stroke: PropTypes.string,
  width: PropTypes.string,
  height: PropTypes.string,
}

export default UserCircleAvatarIcon
