// import { useQuery } from '@tanstack/react-query'
import { statementsApi } from '../../api'
import { useAuthQuery } from '@/hooks/use-auth-query'

export function useAccountSummary() {
  return useAuthQuery({
    queryKey: ['account_summary'],
    queryFn: statementsApi.getAccountSummary,
  })
}
