/**
 * Original response does not include proportion data and it comes empty when there is no rewards data.
 * This formats the response by including proportion data and returns data array according to various scenarios.
 * @param {object} data Response data object from back-end api.
 * @returns {array} Array which includes formatted category based rewards balances.
 */
export const formatRewardsBalanceResponse = data => {
  const total = Number(data?.totalPoints) || 0
  const calcPortion = value => {
    return (Number(value) / total) * 100
  }

  if (!data?.categoryBalances || data?.categoryBalances?.length < 1) {
    return [
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
    ]
  } else if (data?.categoryBalances?.length < 2) {
    return [
      {
        category: data?.categoryBalances[0].category || 'Default',
        points: data?.categoryBalances[0].points,
        portion: calcPortion(data?.categoryBalances[0].points),
      },
      { category: '-', points: '0', portion: 0 },
      { category: '-', points: '0', portion: 0 },
    ]
  } else if (data?.categoryBalances?.length < 3) {
    return [
      {
        category: data?.categoryBalances[0].category || 'Default',
        points: data?.categoryBalances[0].points,
        portion: calcPortion(data?.categoryBalances[0].points),
      },
      {
        category: data?.categoryBalances[1].category || 'Default',
        points: data?.categoryBalances[1].points,
        portion: calcPortion(data?.categoryBalances[1].points),
      },
      { category: '-', points: '0', portion: 0 },
    ]
  } else {
    return [
      {
        category: data?.categoryBalances[0].category || 'Default',
        points: data?.categoryBalances[0].points,
        portion: calcPortion(data?.categoryBalances[0].points),
      },
      {
        category: data?.categoryBalances[1].category || 'Default',
        points: data?.categoryBalances[1].points,
        portion: calcPortion(data?.categoryBalances[1].points),
      },
      {
        category: data?.categoryBalances[2].category || 'Default',
        points: data?.categoryBalances[2].points,
        portion: calcPortion(data?.categoryBalances[2].points),
      },
    ]
  }
}

/**
 * Format original response for the donut chart
 * @param {object} data Response data object from back-end api.
 * @returns {array} Array which includes formatted category based rewards balances.
 */
export const formatRewardsBalanceForDonutChart = data => {
  const total = Number(data?.totalPoints) || 0

  function capitalizeFirstLetter(string) {
    if (string) {
      return string[0]?.toUpperCase() + string.slice(1).toLowerCase()
    } else {
      return 'Default'
    }
  }
  if (!data?.categoryBalances || data?.categoryBalances?.length < 1) {
    return null
  } else if (data?.categoryBalances?.length < 6) {
    return data?.categoryBalances?.map(item => {
      return { name: capitalizeFirstLetter(item?.category), value: Number(item.points) }
    })
  } else {
    let output = data?.categoryBalances?.slice(0, 4).map(item => {
      return { name: capitalizeFirstLetter(item?.category), value: Number(item.points) }
    })
    let accumulatedTotal = output.reduce((acc, item) => acc + item.value, 0)
    output.push({ name: 'Remaining Categories', value: total - accumulatedTotal })
    return output
  }
}
