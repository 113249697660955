import { useState, useRef, useEffect } from 'react'
import { Icons } from '@/theme/Images'
import PropTypes from 'prop-types'

const FileUploadDropZone = ({ onFileUpload, allowedTypes, resetError }) => {
  const [file, setFile] = useState(null)
  const [error, setError] = useState(false)
  const fileInputRef = useRef(null)

  useEffect(() => {
    setError(false)
  }, [resetError])

  const handleDragOver = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const handleDrop = event => {
    event.preventDefault()
    event.stopPropagation()

    const newFile = event.dataTransfer.files[0]
    if (validateFile(newFile)) {
      setFile(newFile)
      onFileUpload(newFile)
      setError(false)
    } else {
      setError(true)
    }
  }

  const handleFileSelect = event => {
    const newFile = event.target.files[0]
    if (validateFile(newFile)) {
      setFile(newFile)
      onFileUpload(newFile)
      setError(false)
    } else {
      setError(true)
    }
  }

  const handleRemoveFile = () => {
    setFile(null)
    onFileUpload()
    setError(false)
    fileInputRef.current.value = ''
  }

  const handleBrowseClick = () => {
    fileInputRef.current.click()
  }

  const validateFile = file => {
    return allowedTypes.includes(file.type)
  }

  return (
    <div>
      {!file && (
        <>
          {!error ? (
            <div
              className="mw-[280px] md:w-[430px] h-[228px] rounded-[12px] border-[2px] border-dashed bg-[#5A80901A] border-brand-600 flex justify-center items-center"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className="flex flex-col items-center gap-2">
                <div className="w-[60px] h-[60px] pb-2">
                  <img src={Icons.DocumentUploadIcon} alt="Upload Icon" draggable="false" />
                </div>
                <div id="document-upload-component" className="h-[20px]">
                  <p className="font-inter text-sm font-bold leading-5 text-center text-dGray-600">
                    Drag & drop files or&nbsp;
                    <span
                      className="font-inter text-sm font-bold leading-5 text-center relative cursor-pointer"
                      onClick={handleBrowseClick}
                    >
                      browse
                      <span className="absolute bottom-[-1px] left-0 w-full h-[1px] bg-dGray-600"></span>
                    </span>
                  </p>
                  <p className="font-inter text-sm font-normal leading-5 text-left text-dGray-600 w-[147px] md:w-[253px]">
                    Supported formats: JPEG, PNG, PDF
                  </p>
                </div>
              </div>
            </div>
          ) : (
            <div
              className="mw-[280px] md:w-[430px] h-[228px] rounded-[12px] border-[2px] border-dashed bg-[#BD3F35] bg-opacity-10 border-error-500 flex justify-center items-center"
              onDragOver={handleDragOver}
              onDrop={handleDrop}
            >
              <div className="flex flex-col items-center gap-2">
                <div className="font-inter text-sm font-bold leading-5 text-center text-dGray-600">
                  <p>Unsupported file format.</p>
                  <p> Please upload a JPEG, PNG, or PDF file.</p>
                </div>
                <div className="w-[60px] h-[60px] pb-2">
                  <img src={Icons.DocumentUploadIcon} alt="Upload Icon" draggable="false" />
                </div>
                <div className="h-[20px]">
                  <p className="font-inter text-sm font-bold leading-5 text-center text-dGray-600">
                    Drag & drop files or&nbsp;
                    <span
                      className="font-inter text-sm font-bold leading-5 text-center relative cursor-pointer"
                      onClick={handleBrowseClick}
                    >
                      browse
                      <span className="absolute bottom-[-1px] left-0 w-full h-[1px] bg-dGray-600"></span>
                    </span>
                  </p>
                </div>
                <div className="h-[20px]">
                  <p className="font-inter text-sm font-normal leading-5 text-left text-dGray-600">
                    Supported formats: JPEG, PNG, PDF
                  </p>
                </div>
              </div>
            </div>
          )}
        </>
      )}
      {file && (
        <>
          <div>
            <p className="font-inter text-sm font-medium leading-5 text-left text-dGray-700">Uploaded Document</p>
          </div>
          <div className="mt-4 flex justify-between items-center border-4 border-[#9E77ED3D] shadow-[inset_0_0_0_1px_#98AFBA] rounded-lg px-3 py-2">
            <span>{file.name}</span>
            <div className="cursor-pointer">
              <img src={Icons.DeleteIcon} alt="icon" className="w-4 h-4 pr-0" onClick={handleRemoveFile} />
            </div>
          </div>
        </>
      )}
      <input
        type="file"
        id="fileInput"
        ref={fileInputRef}
        className="hidden"
        onChange={handleFileSelect}
        accept=".jpeg,.png,.pdf"
        data-testid="fileInput"
      />
    </div>
  )
}

FileUploadDropZone.propTypes = {
  onFileUpload: PropTypes.func.isRequired,
  allowedTypes: PropTypes.array.isRequired,
  resetError: PropTypes.bool,
}

export { FileUploadDropZone }
