import PropTypes from 'prop-types'
import { Badge } from '@/components/ui/badge'
import { Icons } from '@/theme/Images'
import GreenCircleIcon from '../assets/icons/green-dot'

const statusTypes = {
  pending: {
    variant: 'outlined',
    className: 'bg-grayBlue-50 border-[1px] border-grayBlue-200 text-grayBlue-700 text-xs font-medium min-w-[74px]',
    label: 'Pending...',
  },
  scheduled: {
    variant: 'outlined',
    className: 'bg-grayBlue-50 border-[1px] border-grayBlue-200 text-grayBlue-700 text-xs font-medium min-w-[74px]',
    label: 'Scheduled...',
  },
  posted: {
    variant: 'outlined',
    className: 'bg-success-50 border-[1px] border-success-200 text-success-700  text-xs font-medium min-w-[74px]',
    label: (
      <>
        <img src={Icons.greenCheckIcon} alt="check icon" className="mr-1" />
        Posted
      </>
    ),
  },
  active: {
    variant: 'outlined',
    className:
      'bg-success-50 border-[1px] border-success-200 text-success-700  text-xs font-medium min-w-[74px] h-[22px] gap-1',
    label: (
      <>
        <GreenCircleIcon className="w-2 h-2" />
        Active
      </>
    ),
  },
  canceled: {
    variant: 'outlined',
    className:
      ' w-[Hug (53px)] h-[Hug (18px)] justify-center bg-gray-50 border-[1px] border-gray-200 text-dGray-700 text-xs font-medium',
    label: 'Canceled',
  },
  activeDispute: {
    variant: 'outlined',
    className:
      ' w-[Hug (53px)] h-[Hug (18px)] justify-center bg-success-50 border-[1px] border-success-200 text-success-700 text-xs font-medium',
    label: 'Active',
  },
  completeDispute: {
    variant: 'outlined',
    className:
      ' w-[Hug (53px)] h-[Hug (18px)] justify-center bg-gray-50 border-[1px] border-gray-200 text-dGray-700 text-xs font-medium',
    label: 'Complete',
  },
  cancelledDispute: {
    variant: 'outlined',
    className:
      ' w-[Hug (53px)] h-[Hug (18px)] justify-center bg-gray-50 border-[1px] border-gray-200 text-dGray-700 text-xs font-medium',
    label: 'Cancelled',
  },
  declinedDispute: {
    variant: 'outlined',
    className:
      ' w-[Hug (53px)] h-[Hug (18px)] justify-center bg-gray-50 border-[1px] border-error-700 text-error-700 text-xs font-medium',
    label: 'Declined',
  },
}

const StatusBadge = ({ status }) => {
  const badge = statusTypes[status]

  return badge ? (
    <Badge variant={badge.variant} className={badge.className}>
      {badge.label}
    </Badge>
  ) : null
}

StatusBadge.propTypes = {
  status: PropTypes.string,
}

export default StatusBadge
