function SuccessToastIcon() {
  return (
    <div className="relative flex flex-row w-8 h-8 justify-center items-center">
      <div className="absolute rounded-full border-[2px] border-success-700 opacity-10 h-[34px] w-[34px]" />
      <div className="absolute rounded-full border-[2px] border-success-700 opacity-40 h-[26px] w-[26px]" />

      <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M6.25008 9.95345L8.75008 12.4535L13.7501 7.45345M18.3334 9.95345C18.3334 14.5558 14.6025 18.2868 10.0001 18.2868C5.39771 18.2868 1.66675 14.5558 1.66675 9.95345C1.66675 5.35108 5.39771 1.62012 10.0001 1.62012C14.6025 1.62012 18.3334 5.35108 18.3334 9.95345Z"
          stroke="#079455"
          strokeWidth="1.66667"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default SuccessToastIcon
