import PropTypes from 'prop-types'
import { cn, containsSpecialChar } from '@/lib/utils'
import CheckRounded from '@/assets/icons/check-rounded'
import { useEffect } from 'react'

function PasswordChecks({ formik }) {
  const passwordChecks = {
    lengthCheck: formik.values.password.length >= 8,
    specialCharCheck: containsSpecialChar(formik.values.password),
    isValid: formik.values.password.length >= 8 && containsSpecialChar(formik.values.password),
  }

  useEffect(() => {
    formik.setStatus(passwordChecks.isValid)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [passwordChecks.isValid])

  return (
    <div className="flex flex-col items-start self-stretch gap-3">
      <div className="flex flex-row items-start self-stretch gap-2">
        <div
          data-testid="password-length-check"
          className={cn(
            'flex items-center justify-center w-5 h-5 rounded-full',
            passwordChecks.lengthCheck ? 'bg-[--brand-600]' : 'bg-[--gray-300]'
          )}
        >
          <CheckRounded />
        </div>
        <p
          className={cn(
            'text-sm font-normal',
            formik.values.password.length === 0 || passwordChecks.lengthCheck
              ? 'text-[--gray-600]'
              : 'text-[--error-600]'
          )}
        >
          Must be at least 8 characters
        </p>
      </div>
      <div className="flex flex-row items-start self-stretch gap-2">
        <div
          data-testid="password-special-characters-check"
          className={cn(
            'flex items-center justify-center w-5 h-5 rounded-full',
            passwordChecks.specialCharCheck ? 'bg-[--brand-600]' : 'bg-[--gray-300]'
          )}
        >
          <CheckRounded />
        </div>
        <p
          className={cn(
            'text-sm font-normal',
            formik.values.password.length === 0 || passwordChecks.specialCharCheck
              ? 'text-[--gray-600]'
              : 'text-[--error-600]'
          )}
        >
          Must contain one special character
        </p>
      </div>
    </div>
  )
}

PasswordChecks.propTypes = {
  formik: PropTypes.object.isRequired,
}

export default PasswordChecks
