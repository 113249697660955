import './index.css'

import * as Sentry from '@sentry/react'

import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import { Amplify } from 'aws-amplify'
import { AuthProvider } from './contexts/auth.context.jsx'
import { ROUTE_SIGN_IN } from './routes/paths.js'
import { ReactQueryDevtools } from '@tanstack/react-query-devtools'
import { RouterProvider } from 'react-router-dom'
import { StrictMode } from 'react'
import { cognitoUserPoolsTokenProvider } from 'aws-amplify/auth/cognito'
import { createRoot } from 'react-dom/client'
import { defaultStorage } from 'aws-amplify/utils'
import { router } from './routes/router.jsx'

// Initialize Sentry for error tracking and performance monitoring
if (import.meta.env.VITE_SENTRY_ENVIRONMENT !== 'development') {
import.meta.env.VITE_SENTRY_DSN &&
  Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_DSN,
    environment: import.meta.env.VITE_SENTRY_ENVIRONMENT,
    integrations: [Sentry.browserTracingIntegration(), Sentry.replayIntegration()],
    replaysSessionSampleRate: 0.0,
    replaysOnErrorSampleRate: 1.0,
  })
}

Amplify.configure({
  Auth: {
    Cognito: {
      loginWith: {
        oauth: {
          domain: import.meta.env.VITE_OAUTH_DOMAIN,
          scopes: import.meta.env.VITE_OAUTH_SCOPES.split(' '),
          redirectSignIn: [import.meta.env.VITE_APP_DOMAIN],
          redirectSignOut: [import.meta.env.VITE_APP_DOMAIN + ROUTE_SIGN_IN],
          responseType: import.meta.env.VITE_OAUTH_RESPONSE_TYPE,
        },
      },
      userPoolId: import.meta.env.VITE_USER_POOL_ID,
      userPoolClientId: import.meta.env.VITE_USER_POOL_CLIENT_ID,
    },
  },
})

cognitoUserPoolsTokenProvider.setKeyValueStorage(defaultStorage)
const root = createRoot(document.getElementById('root'))

async function enableMocking() {
  if (
    !(
      import.meta.env.MODE === 'test' ||
      (import.meta.env.VITE_AMPLIFY_MOCKS_ENABLED == 'true' && import.meta.env.MODE === 'development')
    )
  ) {
    return
  }

  const { worker } = await import('./mocks/browser')

  return worker.start({
    serviceWorker: {
      url: 'mock-service-worker',
    },
    onUnhandledRequest: 'bypass',
  })
}

const queryClient = new QueryClient()
enableMocking().then(() => {
  root.render(
    <StrictMode>
      <QueryClientProvider client={queryClient}>
        <AuthProvider client={queryClient}>
          <RouterProvider router={router(queryClient)} />
        </AuthProvider>
        {import.meta.env.MODE === 'development' && <ReactQueryDevtools buttonPosition="bottom-right" />}
      </QueryClientProvider>
    </StrictMode>
  )
})
