import { useEffect } from 'react'

const useZendesk = () => {
  useEffect(() => {
    const scriptId = 'ze-snippet'
    const apiKey = import.meta.env.VITE_ZENDESK_KEY
    const scriptSrc = `https://static.zdassets.com/ekr/snippet.js?key=${apiKey}`

    // Function to load the Zendesk widget script
    const loadZendeskWidget = () => {
      if (document.getElementById(scriptId)) return

      const script = document.createElement('script')
      script.id = scriptId
      script.src = scriptSrc

      // Error handling for script loading
      script.onerror = () => {
        console.error('Failed to load the Zendesk widget script.')
      }

      document.body.appendChild(script)
    }

    // Function to remove the Zendesk widget script
    const removeZendeskWidget = () => {
      const script = document.getElementById(scriptId)
      if (script) {
        script.remove()
      }
    }

    // Load the widget on mount
    loadZendeskWidget()

    // Cleanup widget on component unmount
    return () => removeZendeskWidget()
  }, [])
}

export default useZendesk
