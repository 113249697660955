import { commonApi } from '../../api'
import { useMutation } from '@tanstack/react-query'

function useOtp() {
  return useMutation({
    mutationKey: ['send-otp'],
    mutationFn: commonApi.sendOtp,
  })
}

export default useOtp
