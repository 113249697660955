export default function SuccessSubmission() {
  return (
    <svg width="28" height="28" viewBox="0 0 28 28" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.9998 13.9997L9.0148 9.84548C8.27414 9.22826 7.90382 8.91966 7.63758 8.54135C7.40169 8.20616 7.22651 7.83214 7.12002 7.43634C6.99984 6.98963 6.99984 6.50756 6.99984 5.54344V2.33301M13.9998 13.9997L18.9849 9.84548C19.7255 9.22827 20.0959 8.91966 20.3621 8.54135C20.598 8.20616 20.7732 7.83214 20.8797 7.43634C20.9998 6.98963 20.9998 6.50756 20.9998 5.54344V2.33301M13.9998 13.9997L9.0148 18.1539C8.27414 18.7711 7.90382 19.0797 7.63758 19.458C7.40169 19.7932 7.22651 20.1672 7.12002 20.563C6.99984 21.0097 6.99984 21.4918 6.99984 22.4559V25.6663M13.9998 13.9997L18.9849 18.1539C19.7255 18.7711 20.0959 19.0797 20.3621 19.458C20.598 19.7932 20.7732 20.1672 20.8797 20.563C20.9998 21.0097 20.9998 21.4918 20.9998 22.4559V25.6663M4.6665 2.33301H23.3332M4.6665 25.6663H23.3332"
        stroke="black"
        strokeWidth="2.33333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
