import fetchWithAuthorization from './fetch-with-authorization'
import { END_POINTS } from './end-points'

export const getAccountSummary = async () => {
  return await fetchWithAuthorization({
    path: END_POINTS.STATEMENTS.ACCOUNT_SUMMARY,
  })
}

export const getStatementsHistory = async (after = '') => {
  return await fetchWithAuthorization({
    path: END_POINTS.STATEMENTS.STATEMENTS_HISTORY + `?after=${after}`,
  })
}

export const getPdfStatement = async statementID => {
  return await fetchWithAuthorization({
    path: END_POINTS.STATEMENTS.PDF_STATEMENT + `/${statementID}`,
  })
}
