import { Table, TableBody, TableCell, TableRow } from '@/components/ui/table'
import { Skeleton } from '@/components/ui/skeleton'

const TransactionsTableSkeleton = () => {
  return (
    <div className="w-full">
      <Table className="w-full">
        <TableBody>
          {Array.from({ length: 10 }, (_, index) => index + 1)?.map((transaction, index) => (
            <TableRow key={transaction?.id ?? index} className="p-0  h-[72px]">
              <TableCell className="font-normal text-sm text-dGray-600 hidden md:table-cell">
                <Skeleton className="h-4 w-[150px]" />
              </TableCell>
              <TableCell className="hidden md:table-cell">
                <Skeleton className="h-3 w-[70px] ml-[-150px]" />
              </TableCell>
              <TableCell className="w-max px-0 md:px-4 h-full ">
                <div className="flex flex-col h-full">
                  <Skeleton className="h-4 w-[150px]" />
                  <Skeleton className="h-4 w-[120px] mt-2 md:hidden" />
                </div>
              </TableCell>
              <TableCell className={'px-0 md:px-4'}>
                <div className="flex flex-col items-end md:items-end justify-center">
                  <Skeleton className="h-4 w-[70px]" />
                  <div className="md:hidden">
                    <Skeleton className="h-4 w-[70px] mt-2 md:hidden" />
                  </div>
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </div>
  )
}

export default TransactionsTableSkeleton
