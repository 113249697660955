import { useEffect, useState } from 'react'

export const useDynamicFontSize = (ref, scalingFactor) => {
  const initialFontSize = '15'
  const [fontSize, setFontSize] = useState(initialFontSize)

  useEffect(() => {
    const updateFontSize = () => {
      const cardWidth = ref.current.clientWidth

      if (cardWidth !== 0) {
        const calculatedFontSize = cardWidth * scalingFactor
        setFontSize(calculatedFontSize)
      }
    }

    updateFontSize()
    window.addEventListener('resize', updateFontSize)

    return () => {
      window.removeEventListener('resize', updateFontSize)
    }
  }, [ref, scalingFactor])

  return fontSize
}
