import './confirmation-code-input.css'

import PropTypes from 'prop-types'
import VerificationInput from 'react-verification-input'
import { cn } from '@/lib/utils'

function ConfirmationCodeInput({ code, setCode, length }) {
  return (
    <VerificationInput
      value={code}
      validChars="0-9"
      placeholder="0"
      length={length}
      onChange={setCode}
      classNames={{
        container: 'flex flex-row justify-between gap-3 md:gap-2 p-0 w-full h-full', //TODO: md:gap-5
        character: cn(
          'flex items-center justify-center w-[44px] h-[44px] md:w-[64px] md:h-[64px] text-3xl md:text-5xl rounded-md border border-input font-medium',
          code ? '' : 'text-gray-300'
        ),
        characterInactive: 'bg-inherit text-gray-300',
        characterSelected: 'outline-brand-600 text-brand-700 character--selected shadow-[0_0px_12px_#5A8090]',
        characterFilled: 'outline outline-2 outline-brand-600 text-brand-600 character--filled',
      }}
    />
  )
}

ConfirmationCodeInput.propTypes = {
  code: PropTypes.string,
  setCode: PropTypes.func,
  length: PropTypes.number,
}

export { ConfirmationCodeInput }
