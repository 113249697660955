import { useAuthQuery } from '@/hooks/use-auth-query'
import { rewardsApi } from '@/api'

function useGetRewardBalance() {
  return useAuthQuery({
    queryKey: ['reward_balance'],
    queryFn: rewardsApi.getRewardsBalance,
  })
}

export default useGetRewardBalance
