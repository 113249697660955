import PropTypes from 'prop-types'

import { Checkbox as ShadcnCheckbox } from './ui/checkbox'

function Checkbox({ label, helpText, onCheckedChange, checked, touched, error, ...props }) {
  return (
    <div className="flex flex-1 items-start gap-2">
      <div className="items-top flex space-x-2">
        <ShadcnCheckbox
          id="terms1"
          onCheckedChange={onCheckedChange}
          checked={checked}
          className={`form-checkbox h-4 w-4 text-blue-600 ${touched && error ? 'border-red-500' : ''}`}
          {...props}
        />
        <div className="grid gap-1.5 leading-none items-center">
          <label
            htmlFor="terms1"
            className="text-md font-medium leading-none text-[--gray-700] peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
          >
            {label}
          </label>
          {helpText && <p className="text-sm text-muted-foreground">{helpText}</p>}
          {touched && error && <p className="text-sm text-red-500">{error}</p>}
        </div>
      </div>
    </div>
  )
}

Checkbox.propTypes = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  onCheckedChange: PropTypes.func.isRequired,
  checked: PropTypes.bool.isRequired,
  touched: PropTypes.bool,
  error: PropTypes.string,
}

export { Checkbox }
