import { END_POINTS } from './end-points'
import fetchWithAuthorization from './fetch-with-authorization'

export const activateCard = async body => {
  body.last4 = body.last4.toString()
  body.cvv = body.cvv.toString()
  return await fetchWithAuthorization({
    path: END_POINTS.CARD_MANAGEMENT.ACTIVATE_CARD,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const updateCardStatus = async ({ consumerCardId = '', status = '' }) => {
  return await fetchWithAuthorization({
    path: END_POINTS.CARD_MANAGEMENT.UPDATE_CARD_STATUS + `?consumerCardId=${consumerCardId}&status=${status}`,
    method: 'PUT',
  })
}

export const getSecureCardDetails = async body => {
  return await fetchWithAuthorization({
    path: END_POINTS.CARD_MANAGEMENT.VIEW_SECURE_CARD_DETAILS,
    method: 'POST',
    body: JSON.stringify(body),
  })
}

export const reissueCard = async body => {
  return await fetchWithAuthorization({
    path: END_POINTS.CARD_MANAGEMENT.REISSUE_CARD,
    method: 'POST',
    body: JSON.stringify(body),
  })
}
