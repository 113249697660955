import { useState } from 'react'
import PropTypes from 'prop-types'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@/components/ui/tooltip'

const ExtendedTooltip = ({ trigger, content, title }) => {
  const [isOpen, setIsOpen] = useState(false)

  const handleToggle = () => {
    setIsOpen(!isOpen)
  }

  const handleClose = () => {
    setIsOpen(false)
  }

  return (
    <TooltipProvider>
      <Tooltip open={isOpen} onOpenChange={setIsOpen}>
        <TooltipTrigger asChild>
          <span onClick={handleToggle}>{trigger}</span>
        </TooltipTrigger>
        <TooltipContent className="bg-dGray-900 max-w-[320px]" onClick={handleClose}>
          {title ? <p className="font-semibold text-white text-xs mb-1">{title}</p> : null}
          <p className="font-medium text-dGray-300 text-xs">{content}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  )
}

ExtendedTooltip.propTypes = {
  trigger: PropTypes.element.isRequired,
  content: PropTypes.string.isRequired,
  title: PropTypes.string,
}

export default ExtendedTooltip
