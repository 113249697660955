import { useAuth } from '@/contexts/auth.context'
import { useEffect } from 'react'
import { useQuery } from '@tanstack/react-query'

export const useAuthQuery = (query, hookQuery = useQuery) => {
  const { refreshUserSession, isTokenRefreshing, setIsTokenRefreshing } = useAuth()

  const res = hookQuery({
    ...query,
    enabled: !isTokenRefreshing, // Enable query if not refreshing but should-refetching
  })

  useEffect(() => {
    if (res.data?.statusCode === 401) {
      const refreshAndRefetch = async () => {
        await refreshUserSession() // Trigger session refresh
        res.refetch() // Refetch the query after refreshing
      }
      setIsTokenRefreshing(true)
      refreshAndRefetch()
    }

    // TODO: Need to add loading page when refetching is on-going
    // if (res.isFetching){
    //   setLoading(true)
    // }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [res.data, refreshUserSession, res])

  return res
}
