import { Select, SelectContent, SelectGroup, SelectItem, SelectTrigger, SelectValue } from '@/components/ui/select'

import PropTypes from 'prop-types'
import { cn } from '@/lib/utils'

const Dropdown = ({
  value,
  placeholder,
  onChange,
  label,
  options = [],
  helpText,
  onBlur,
  className,
  disabled = false,
}) => {
  return (
    <div className={cn('flex flex-col items-start self-stretch gap-1.5', className)}>
      <div className="flex flex-col items-start self-stretch gap-1.5">
        <p className="text-sm font-medium text-[--gray-700]">{label}</p>
        <Select className="border-0 focus-visible:ring-0 " value={value} onValueChange={onChange}>
          <SelectTrigger
            className={`${value ? 'text-[--gray-900]' : 'font-[400] text-[--gray-500]'} ${className}`}
            disabled={disabled}
            onBlur={onBlur}
          >
            <SelectValue placeholder={placeholder} value={value} data-testid="dropdown"></SelectValue>
          </SelectTrigger>
          <SelectContent className="bg-white">
            <SelectGroup>
              {options.map((option, index) => (
                <SelectItem key={index} value={option.value} className="hover:bg-[--gray-50]">
                  {option.label}
                </SelectItem>
              ))}
            </SelectGroup>
          </SelectContent>
        </Select>
      </div>
      {helpText}
    </div>
  )
}

Dropdown.propTypes = {
  value: PropTypes.string,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  helpText: PropTypes.element,
  className: PropTypes.string,
  disabled: PropTypes.bool,
}
export { Dropdown }
