//Utility function to format amount for transaction tables with +,$ signs and decimal places and text color
export const transformAmount = (amount, sign, withoutDollarSign = false) => {
  const formattedAmount = new Intl.NumberFormat('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  }).format(amount / 100)

  if (sign) {
    if (amount === 0) {
      return withoutDollarSign ? '0.00' : '$0.00'
    } else if (sign === 'NEGATIVE' && amount) {
      return withoutDollarSign ? `+ ${formattedAmount}` : `+ $${formattedAmount}`
    } else {
      return withoutDollarSign ? `- ${formattedAmount}` : `- $${formattedAmount}`
    }
  } else {
    if (amount === 0) {
      return withoutDollarSign ? '0.00' : '$0.00'
    } else {
      return withoutDollarSign ? formattedAmount : `$${formattedAmount}`
    }
  }
}

//Utility function to format amount for account summary section
export const transformSummaryAmount = amount => {
  return Intl.NumberFormat('us-en', { style: 'currency', currency: 'USD' }).format((amount / 100).toFixed(2))
}

/**
 * According to given periodStart date string and periodEnd date string, this calculates
 * the month which effects mostly to the statement with the relevant year.
 * @param {string} periodStart Billing cycle start date string.
 * @param {string} periodEnd Billing cycle end date string.
 * @returns {object} object which includes statement month and year.
 */
export const getStatementMonth = (periodStart, periodEnd) => {
  const startDate = new Date(periodStart)
  const endDate = new Date(periodEnd)

  const startDateMonth = startDate.getMonth()

  // Find the month with the most days in the range
  let monthCounts = new Map()
  for (let date = startDate; date <= endDate; date.setDate(date.getDate() + 1)) {
    const month = date.getMonth() + 1
    monthCounts.set(month, (monthCounts.get(month) || 0) + 1)
  }

  let maxMonth = null
  let maxCount = 0
  for (const [month, count] of monthCounts) {
    if (count > maxCount) {
      maxMonth = month
      maxCount = count
    }
  }

  const monthNames = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sept', 'Oct', 'Nov', 'Dec']
  const year = maxMonth - 1 === startDateMonth ? new Date(periodStart).getFullYear() : new Date(periodEnd).getFullYear()
  return { month: monthNames[maxMonth - 1], year: year }
}
