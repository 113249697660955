import PropTypes from 'prop-types'

import { Button } from '@/components'

const StaticAnnouncement = ({ startIcon, text, action }) => {
  return (
    <div className="rounded-xl h-auto md:h-[72px] w-[100%] border-dGray-300 bg-white border-[1px] shadow-[0px_15px_30px_-20px_rgba(0,0,0,0.3)] flex flex-col md:flex-row items-center justify-between px-3 pt-3 md:pt-0">
      <div className="flex flex-row items-center justify-start">
        {startIcon ? <img src={startIcon} alt="announcement-icon" width={'48px'} height={'48px'} /> : null}
        {text && <span className="font-regular text-base ml-2 md:ml-4 text-dGray-700">{text}</span>}
      </div>
      <div className="md:w-auto w-full my-4 md:m-0 md:pl-1">{action ? action : null}</div>
    </div>
  )
}

const StaticAnnouncementAction = ({ text, handleClick, disabled }) => {
  return (
    <Button
      variant="outline"
      className={`font-[600] text-dGray-700 text-md w-full ${disabled ? 'hover:bg-white' : 'hover:bg-dGray-100'}`}
      onClick={handleClick}
    >
      {text}
    </Button>
  )
}

StaticAnnouncement.propTypes = {
  startIcon: PropTypes.string,
  text: PropTypes.string,
  action: PropTypes.object,
}
StaticAnnouncementAction.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool,
}

export { StaticAnnouncement, StaticAnnouncementAction }
