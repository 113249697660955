export function getCurrentUserSessionID() {
  return localStorage.getItem(`CognitoIdentityServiceProvider.${import.meta.env.VITE_USER_POOL_CLIENT_ID}.LastAuthUser`)
}

export function getCurrentUserSessionAccessToken() {
  const userID = getCurrentUserSessionID()
  return localStorage.getItem(
    `CognitoIdentityServiceProvider.${import.meta.env.VITE_USER_POOL_CLIENT_ID}.${userID}.accessToken`
  )
}
