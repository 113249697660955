export default function CheckOutlined() {
  return (
    <svg width="26" height="26" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M7.75016 13.0002L11.2502 16.5002L18.2502 9.50016M24.6668 13.0002C24.6668 19.4435 19.4435 24.6668 13.0002 24.6668C6.55684 24.6668 1.3335 19.4435 1.3335 13.0002C1.3335 6.55684 6.55684 1.3335 13.0002 1.3335C19.4435 1.3335 24.6668 6.55684 24.6668 13.0002Z"
        stroke="#344054"
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  )
}
