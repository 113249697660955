import { useMutation, useQueryClient } from '@tanstack/react-query'
import { plaidApi } from '../../api'

export function usePublicToken({ monitor = false } = {}) {
  const queryClient = useQueryClient()

  return useMutation({
    mutationKey: ['plaid-public-token'],
    mutationFn: payload => plaidApi.exchangePublicToken({ ...payload, monitor }),
    onSuccess: data => {
      queryClient.setQueryData(['externalBankAccounts'], oldData => {
        return oldData ? [...oldData, { node: data[0] }] : [{ node: data[0] }]
      })
      if (monitor) queryClient.setQueryData(['plaid-banner'], true)
    },
  })
}
