function ShieldSecurityIcon() {
  return (
    <div className="flex flex-row w-7 h-7 justify-center items-center ml-1 mt-1 bg-white">
      <svg width="120" height="120" viewBox="10 10 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17 19.4999L19 21.4999L23.5 16.9999M28 19.9999C28 24.9083 22.646 28.4783 20.698 29.6147C20.4766 29.7439 20.3659 29.8085 20.2097 29.842C20.0884 29.868 19.9116 29.868 19.7903 29.842C19.6341 29.8085 19.5234 29.7439 19.302 29.6147C17.354 28.4783 12 24.9083 12 19.9999V15.2175C12 14.418 12 14.0182 12.1308 13.6746C12.2463 13.371 12.434 13.1001 12.6777 12.8854C12.9535 12.6423 13.3278 12.5019 14.0764 12.2212L19.4382 10.2105C19.6461 10.1326 19.75 10.0936 19.857 10.0782C19.9518 10.0644 20.0482 10.0644 20.143 10.0782C20.25 10.0936 20.3539 10.1326 20.5618 10.2105L25.9236 12.2212C26.6722 12.5019 27.0465 12.6423 27.3223 12.8854C27.566 13.1001 27.7537 13.371 27.8692 13.6746C28 14.0182 28 14.418 28 15.2175V19.9999Z"
          stroke="black"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default ShieldSecurityIcon
