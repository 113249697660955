import { useRouteError } from 'react-router-dom'
import { Images } from '@/theme/Images'

export default function ErrorPage() {
  const error = useRouteError()
  console.error(error)

  return (
    <div>
      <div className="bg-brand h-[72px] w-full sticky top-0 z-20 px-[5%] md:px-[10%] flex flex-row flex-wrap justify-between items-center">
        <div className="flex flex-row justify-start items-center">
          <img src={Images.fastenTextLogoWhite} alt="fasten text logo" className="w-[8rem] h-auto" />
        </div>
      </div>
      <div className="h-screen bg-bgGray flex flex-col items-center pt-16">
        <div className="rounded-xl bg-white shadow-[0_1px_3px_0_rgba(16,24,40,0.1)] py-2 mb-4">
          <div className="flex flex-col flex-grow py-8 pl-6 md:px-48 pr-6 pt-6 justify-center items-center">
            <div className="text-center">
              <h1 className="text-5xl font-bold text-gray-800 mb-4">Oops!</h1>
              <p className="text-lg text-brand-800 mb-6 mx-8">
                Looks like we took a wrong turn and missed the page you&apos;re looking for.
                <br />
                Let&apos;s get you back on track.
              </p>
              <p className="text-left text-l font-semibold text-gray-800 mb-4">
                Error Code: 404
                {/* Improvement: to show specific errors  <i>{error.statusText || error.message}</i> */}
              </p>
              <hr />
              <div className="text-left mt-6">
                <p className="text-left text-l font-semibold text-gray-800">Get Back on Track Here:</p>
                <ul className="list-disc list-inside text-brand-800">
                  <li>
                    <a href="/" className="underline">
                      Home
                    </a>
                  </li>
                  <li>
                    <a href="/account/more" className="underline">
                      Contact Us
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
