import { plaidApi } from '../../api'
import { useAuthQuery } from '@/hooks/use-auth-query'

// import { useQuery } from '@tanstack/react-query'

export function useLinkToken() {
  return useAuthQuery({
    queryKey: ['plaid-link-token'],
    queryFn: plaidApi.createLinkToken,
  })
}
