import { useState } from 'react'
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Button, CreditCard, ConfirmationCodeInput, Divider, CreditCardBack } from '@/components'
import { Icons } from '@/theme/Images'
import { dummyData } from '@/data'
import useOtp from '../hooks/use-otp'
import useGetSecureCardDetails from '../hooks/use-get-secure-card-details'
import { toast } from 'sonner'
import { ToastMessage } from '../../components/toast-message'
import { useAuth } from '../../contexts/auth.context'

const CardDetailsDialog = () => {
  const [isVerified, setIsVerified] = useState(false)
  const [code, setCode] = useState('')
  const [isCodeSent, setIsCodeSent] = useState(false)
  const [codeError, setCodeError] = useState()
  const [isOpen, setIsOpen] = useState()
  const { user } = useAuth()

  const {
    mutateAsync: getSecureCardDetails,
    data: cardData,
    isPending: getSecureDetailsPending,
  } = useGetSecureCardDetails()

  const { mutateAsync: sendOtp, isPending: otpPending } = useOtp()

  //Handle verify button click
  const handleVerify = async () => {
    await getSecureCardDetails({ code: code })
      .then(() => setIsVerified(true))
      .catch(() => setCodeError('The code is incorrect or expired. Try again.'))
  }

  //Handle events on open and close
  const handleDialogOpenClose = val => {
    setIsOpen(val)
    setIsVerified(false)
    setCodeError('')
    setCode()
    setIsCodeSent(false)
  }

  //Handle verification code change
  const handleVerificationCodeChange = input => {
    setCode(input)
    setCodeError()
  }

  //Handle sending OTP code
  const handleOTPSend = async () => {
    await sendOtp()
      .then(() => setIsCodeSent(true))
      .catch(err =>
        toast.custom(() => (
          <ToastMessage
            text="Failed"
            description={
              err.errors === 'Too many requests'
                ? 'Please wait 2 minutes before requesting another verification code.'
                : `${err.message}.`
            }
          />
        ))
      )
  }

  //Handle resend code
  const onResendCode = () => {
    handleOTPSend()
    setIsCodeSent(true)
    setCodeError('')
    setCode('')
  }

  //Clear code on invalid code submit
  const handleInvalidCode = () => {
    if (codeError) {
      setCodeError('')
      setCode('')
    }
  }

  return (
    <Dialog onOpenChange={handleDialogOpenClose} open={isOpen}>
      <DialogTrigger asChild>
        <p
          className="my-3 underline text-[12px] font-medium text-dGray-700 cursor-pointer"
          data-testid="card-detail-dialog-trigger"
        >
          View card details
        </p>
      </DialogTrigger>
      <DialogContent
        className="max-w-[92%] rounded-lg md:max-w-[480px] p-0"
        data-testid="card-dialog-content"
        onInteractOutside={event => {
          event.preventDefault()
        }}
      >
        <DialogHeader className="p-5 pb-4">
          <div className="flex flex-row justify-start items-start">
            <img src={Icons.filledCardIcon} alt="icon" width={48} />
            <div className="ml-5 flex flex-col justify-center items-start">
              <DialogTitle className="leading-7 text-dGray-900 text-lg font-semibold">Card Details</DialogTitle>
              {!isCodeSent ? (
                <DialogDescription className="text-dGray-600 text-sm font-normal text-start">
                  To view your card details, we need you to verify your identity. We&apos;ll send a confirmation text to
                  (***) ***-{user?.customer?.maskedPhoneNumber?.slice(-4)}. Please note that the code will expire 10
                  minutes after being sent.
                </DialogDescription>
              ) : isVerified ? (
                <DialogDescription className="text-dGray-600 text-sm font-normal text-start">
                  Use this card anywhere you shop or pay online.
                </DialogDescription>
              ) : (
                <DialogDescription className="text-dGray-600 text-sm font-normal text-start">
                  Please enter the temporary 6-digit code we sent to (***) ***-
                  {user.customer.maskedPhoneNumber?.slice(-4)}. Didn&apos;t receive a code?{' '}
                  <span
                    className="text-dGray-600 text-sm font-normal text-start underline cursor-pointer md:inline block"
                    onClick={onResendCode}
                  >
                    Resend Code
                  </span>
                </DialogDescription>
              )}
            </div>
          </div>
        </DialogHeader>
        {isCodeSent ? (
          <>
            <Divider />
            <div className="px-5 pt-4">
              {!isVerified ? (
                <CreditCard masked={!isVerified} data={dummyData.mockCreditCardData} blurred={true} />
              ) : (
                <CreditCardBack masked={!isVerified} data={cardData} user={user} />
              )}
              {!isVerified ? (
                <>
                  <p className="text-dGray-700 text-sm font-medium mb-[-10px] my-2">Code</p>
                  <div className="mt-5 mb-8" onClick={handleInvalidCode}>
                    <ConfirmationCodeInput code={code} setCode={handleVerificationCodeChange} length={6} />
                  </div>
                </>
              ) : (
                <div className="relative flex flex-row justify-start items-center px-1 py-8">
                  <div className="relative flex justify-center items-center">
                    <img src={Icons.checkCircleIcon} className="w-[17.5px]" />
                    <div className="absolute rounded-full border-2 border-success-700 opacity-10 h-9 w-9" />
                    <div className="absolute rounded-full border-2 border-success-700 opacity-40 h-[26px] w-[26px]" />
                  </div>
                  <p className="ml-6 text-sm font-semibold text-dGray-900">Verification was successful</p>
                </div>
              )}
              {codeError && !isVerified ? (
                <div className="relative flex flex-row justify-start items-center px-1 pb-8 mt-[-18px]">
                  <div className="relative flex justify-center items-center mt-[-15px] md:mt-0">
                    <img src={Icons.xCircleIcon} />
                    <div className="absolute rounded-full border-2 border-error-700 opacity-10 h-9 w-9" />
                    <div className="absolute rounded-full border-2 border-error-700 opacity-40 h-[26px] w-[26px]" />
                  </div>
                  <p className="ml-6 text-sm font-semibold text-dGray-900">{codeError}</p>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        <Divider />
        <DialogFooter className="w-full px-5">
          <Button
            variant="outline"
            className="w-full hover:bg-dGray-100"
            disabled={isVerified ? true : false}
            onClick={() => setIsOpen(false)}
          >
            Cancel
          </Button>
          {!isCodeSent ? (
            <Button className="w-full bg-brand-600 hover:bg-brand-700" disabled={otpPending} onClick={handleOTPSend}>
              Send Code
            </Button>
          ) : isVerified ? (
            <Button
              className="w-full bg-brand-600 hover:bg-brand-700"
              disabled={code?.length === 6 ? false : true}
              onClick={() => setIsOpen(false)}
            >
              Close
            </Button>
          ) : (
            <>
              <Button
                className="w-full bg-brand-600 hover:bg-brand-700"
                disabled={code?.length === 6 && !getSecureDetailsPending ? false : true}
                onClick={handleVerify}
              >
                Verify
              </Button>
            </>
          )}
        </DialogFooter>
      </DialogContent>
    </Dialog>
  )
}

export default CardDetailsDialog
