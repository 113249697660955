import { cardManagementApi } from '../../api'
import { useMutation } from '@tanstack/react-query'

function useGetSecureCardDetails() {
  return useMutation({
    mutationKey: ['secure-card-details'],
    mutationFn: data => cardManagementApi.getSecureCardDetails(data),
  })
}

export default useGetSecureCardDetails
