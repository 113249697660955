import { useRef, useMemo } from 'react'
import PropTypes from 'prop-types'

import { Images } from '@/theme/Images'

const CreditCardBack = ({ data, masked, blurred, width, height, user }) => {
  const cardRef = useRef(null)

  // Memoizing the card number according to current state
  const cardNumber = useMemo(() => {
    if (masked) {
      return `•••• •••• •••• ${data?.cardNumber?.slice(-4)}`
    } else {
      return data?.cardNumber?.replace(/(\d{4})(?=\d)/g, '$1 ')
    }
  }, [data?.cardNumber, masked])

  let month = data?.expirationDate?.substring(5, 7)
  let year = data?.expirationDate?.substring(2, 4)

  return (
    <div
      className={`w-full h-auto relative inline-block ${blurred ? 'blur' : ''}`}
      data-testid="credit-card-mockup-container"
    >
      <img src={Images.creditCardBackground} alt="credit card mockup" ref={cardRef} width={width} height={height} />
      <div className="absolute top-[10%] left-0 w-full h-[20%] bg-black" />
      <div className="absolute top-[40%] left-[35%] text-white font-manrope text-[16.15px] md:text-[25px] font-bold leading-[22.06px] md:leading-[34.15px] text-left">
        {cardNumber}
      </div>
      <div className="flex flex-row absolute top-[55%] left-[35%] gap-8">
        <div className=" flex flex-col text-white font-manrope text-[7.75px] md:text-xs font-semibold leading-[10.59px] md:leading-[16.39px] tracking-[0.05em] text-left">
          Exp. Date <br />
          <p className="font-manrope  text-white text-[10.59px] md:text-[16.41px] font-semibold leading-[14.47px] md:leading-[22.41px] tracking-[0.05em] text-left">
            {`${month}/${year}`}
          </p>
        </div>
        <div className=" flex flex-col text-white font-manrope text-[7.75px] md:text-xs font-semibold leading-[10.59px] md:leading-[16.39px] tracking-[0.05em] text-left">
          Security Code <br />
          <p className="font-manrope text-white text-[10.59px] md:text-[16.41px] font-semibold leading-[14.47px] md:leading-[22.41px] tracking-[0.05em] text-left">
            {' '}
            {data?.cvv}
          </p>
        </div>
      </div>
      <div className="absolute top-[70%] left-[35%] pt-3 md:pt-4 text-white font-manrope text-xs md:text-[16.41px] font-semibold leading-[14.47px] md:leading-[22.41px] tracking-[0.05em] text-left">
        {`${user?.customer?.firstName?.toUpperCase()} ${user?.customer?.lastName?.toUpperCase()}`}
      </div>
    </div>
  )
}

CreditCardBack.propTypes = {
  data: PropTypes.object,
  masked: PropTypes.bool,
  blurred: PropTypes.bool,
  width: PropTypes.string,
  height: PropTypes.string,
  user: PropTypes.object,
}

export default CreditCardBack
