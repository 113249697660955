// import { useQuery } from '@tanstack/react-query'

import { plaidApi } from '../../api'
import { useAuthQuery } from '@/hooks/use-auth-query'

const useFetchPlaidBanner = () => {
  return useAuthQuery({
    queryKey: ['plaid-banner'],
    queryFn: plaidApi.fetchPlaidBannerStatus,
  })
}

export default useFetchPlaidBanner
