import { useState } from 'react'
import { useNavigate } from 'react-router'
import PropTypes from 'prop-types'

import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
  DropdownMenuItem,
} from '@/components/ui/dropdown-menu'
import { Icons } from '@/theme/Images'
import { routes } from '@/data'
import { Input } from '../index'

const MobileNavbar = ({ searchQuery, handleSearch, handleSearchItemClick, suggestions, dropDownRef }) => {
  const navigate = useNavigate()
  const pathname = location.pathname.split('/')[1]

  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropdownMenu onOpenChange={d => setIsOpen(d)} modal={true} open={isOpen}>
      <DropdownMenuTrigger asChild>
        <img src={isOpen ? Icons.boldCloseIcon : Icons.menuIcon} alt="icon" className="mr-3 block md:hidden" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-brand text-white border-none w-[100vw] rounded-none mt-5 md:hidden">
        <div className="relative px-3">
          {<img src={Icons.searchIcon} className="absolute top-[25%] left-5" />}
          <Input
            placeholder="Search"
            className="bg-brand-600 border-none px-8 text-lg focus:outline-none"
            onChange={e => handleSearch(e)}
            value={searchQuery}
          />
          {suggestions && searchQuery?.length > 0 ? (
            <ul
              ref={dropDownRef}
              className="absolute max-h-[700%] lg:max-h-72 overflow-hidden overflow-y-auto bg-white w-[94%] py-3 px-1 shadow-md rounded-lg dropdown-suggestions z-10"
            >
              {suggestions?.map((item, index) => (
                <li
                  key={index}
                  className="py-2 px-2 font-semibold cursor-pointer hover:bg-dGray-200 rounded-lg z-10 text-black"
                  onClick={() => {
                    handleSearchItemClick(item)
                    setIsOpen(false)
                  }}
                >
                  {item}
                </li>
              ))}
            </ul>
          ) : null}
        </div>
        <DropdownMenuSeparator />
        {routes?.map(item => (
          <DropdownMenuItem
            key={item?.name}
            onClick={() => navigate(item?.path)}
            className={`${pathname === item?.path.split('/')[0] ? 'font-semibold text-white' : 'text-dGray-300'} text-md my-5 px-3`}
          >
            {item?.name}
          </DropdownMenuItem>
        ))}
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

MobileNavbar.propTypes = {
  searchQuery: PropTypes.string,
  suggestions: PropTypes.array,
  handleSearch: PropTypes.func,
  handleSearchItemClick: PropTypes.func,
  dropDownRef: PropTypes.object,
}

export default MobileNavbar
