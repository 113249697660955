import { Link } from 'react-router-dom'
import { Icons } from '@/theme/Images'
import { useGetRecurringPayment } from '../../hooks/use-get-recurring-payment'

export default function AutoPayIndicator() {
  const { data } = useGetRecurringPayment()
  const autoPayActive = ['SCHEDULED', 'ACTIVE'].includes(data?.status)

  return (
    <div className="flex flex-row justify-start items-center pt-2 md:justify-end md:ml-6 md:mt-[-5px]">
      <img src={autoPayActive ? Icons.blueIndicatorIcon : Icons.grayIndicatorIcon} alt="icon" className="mr-1" />
      <p className="text-right text-nowrap font-medium text-[12px] text-dGray-700">
        AutoPay {autoPayActive ? 'on' : 'off'}
      </p>
      {!autoPayActive ? (
        <Link
          className="text-right font-medium text-[12px] text-dGray-700 underline cursor-pointer ml-2"
          to="/payments/auto-pay"
        >
          <p className="text-nowrap">Start AutoPay</p>
        </Link>
      ) : null}
    </div>
  )
}
