const mockSearchData = [
  'Honda Civic',
  'Mitsubishi Lancer Evolution',
  'Toyota Camry',
  'Ford Mustang',
  'Chevrolet Impala',
  'BMW 3 Series',
  'Mercedes-Benz C-Class',
  'Subaru Outback',
  'Volkswagen Golf',
  'Nissan Altima',
  'Hyundai Elantra',
  'Audi A4',
  'Kia Soul',
  'Jaguar F-Type',
  'Tesla Model S',
  'Porsche 911',
  'Dodge Charger',
  'Lexus RX',
  'Jeep Wrangler',
  'Mazda CX-5',
]

const mockCreditCardData = {
  cardNumber: '1231 1232 1233 1234',
  expDate: '07/26',
  cvv: '123',
  cardHolderName: 'Olivia Rhye',
}

const mockRecentTransactionsData = [
  {
    id: '728ed52f',
    amount: '- $18.99',
    status: 'pending',
    description: 'Test description',
    date: 'Dec 22, 2023',
  },
  {
    id: '489e1d42',
    amount: '- $4.50',
    status: 'pending',
    description: 'Test description',
    date: 'Dec 20, 2023',
  },
  {
    id: '728edss52f',
    amount: '+ $88.00',
    status: 'pending',
    description: 'Test description',
    date: 'Dec 12, 2023',
  },
  {
    id: '489e1dnn42',
    amount: '- $4.50',
    status: 'posted',
    description: 'Test description',
    date: 'Dec 03, 2023',
  },
  {
    id: '728end52f',
    amount: '- $27.99',
    status: 'posted',
    description: 'Test description',
    date: 'Nov 22, 2023',
  },
  {
    id: '489e1dgq42',
    amount: '- $40.20',
    status: 'posted',
    description: 'Test description',
    date: 'Nov 15, 2023',
  },
  {
    id: '728edq52f',
    amount: '- $8.50',
    status: 'pending',
    description: 'Test description',
    date: 'Nov 07, 2023',
  },
  {
    id: '489e1dw42',
    amount: '- $120.56',
    status: 'posted',
    description: 'Test description',
    date: 'Nov 01, 2023',
  },
  {
    id: '728ged52f',
    amount: '- $320.00',
    status: 'pending',
    description: 'Test description',
    date: 'Oct 28, 2023',
  },
  {
    id: '489e41d42',
    amount: '+ $125.00',
    status: 'posted',
    description: 'Test description',
    date: 'Oct 19, 2023',
  },
]

const transactionOriginalRowContents = [
  {
    date: `Dec 22, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    color: 'blue',
    amount: `-$4.50`,
  },
  {
    date: `Dec 22, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'pink',
    amount: `+$88.50`,
  },
  {
    date: `Dec 22, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    amount: `+$4.50`,
  },
  {
    date: `Dec 20, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    color: 'green',
    amount: `-$4.50`,
  },
  {
    date: `Dec 20, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'blue',
    amount: `-$27.99`,
  },
  {
    date: `Dec 20, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    color: 'pink',
    amount: `-$40.20`,
  },
  {
    date: `Dec 18, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `+$18.26`,
  },
  {
    date: `Dec 18, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$120.56`,
  },
  {
    date: `Dec 18, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$320.00`,
  },
  {
    date: `Dec 18, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$320.00`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    color: 'blue',
    amount: `-$4.50`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'pink',
    amount: `+$88.50`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    amount: `+$4.50`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    color: 'green',
    amount: `-$4.50`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'blue',
    amount: `-$27.99`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    color: 'pink',
    amount: `-$40.20`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `+$18.26`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$120.56`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'blue',
    amount: `-$320.00`,
  },
  {
    date: `Nov 27, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$320.00`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'pending',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'blue',
    amount: `-$320.00`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$40.20`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    color: 'pink',
    amount: `-$320.00`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    color: 'pink',
    amount: `+$18.26`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$120.00`,
  },
  {
    date: `Oct 10, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    color: 'green',
    amount: `-$320.00`,
  },
]

const statementsPdfTitle = [
  { transactionMonth: 'Dec', transactionYear: 2023 },
  { transactionMonth: 'Nov', transactionYear: 2023 },
  { transactionMonth: 'Oct', transactionYear: 2023 },
  { transactionMonth: 'Sept', transactionYear: 2023 },
  { transactionMonth: 'Aug', transactionYear: 2023 },
  { transactionMonth: 'Jul', transactionYear: 2023 },
  { transactionMonth: 'Jun', transactionYear: 2023 },
  { transactionMonth: 'May', transactionYear: 2023 },
]

const mockNotifications = [
  {
    id: 1,
    title: 'This is notification one',
    description: 'This is notification one description',
    date: new Date(),
    isRead: false,
  },
  {
    id: 2,
    title: 'This is notification two',
    description: 'This is notification two description',
    date: new Date('2024-02-29 13:05:29.175+05:30'),
    isRead: false,
  },
  {
    id: 3,
    title: 'This is notification three',
    description: 'This is notification three description',
    date: new Date('2024-02-29 12:55:29.175+05:30'),
    isRead: false,
  },
  {
    id: 4,
    title: 'This is notification four',
    description: 'This is notification four description',
    date: new Date('2024-02-29 11:35:29.175+05:30'),
    isRead: true,
  },
  {
    id: 5,
    title: 'This is notification five',
    description: 'This is notification five description',
    date: new Date('2024-02-28 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 6,
    title: 'This is notification six',
    description: 'This is notification six description',
    date: new Date('2024-02-24 16:46:29.175+05:30'),
    isRead: false,
  },
  {
    id: 7,
    title: 'This is notification seven',
    description: 'This is notification seven description',
    date: new Date('2024-02-12 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 8,
    title: 'This is notification eight',
    description: 'This is notification eight description',
    date: new Date('2024-02-08 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 9,
    title: 'This is notification nine',
    description: 'This is notification nine description',
    date: new Date('2024-01-27 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 10,
    title: 'This is notification ten',
    description: 'This is notification ten description',
    date: new Date('2024-01-27 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 11,
    title: 'This is notification eleven',
    description: 'This is notification eleven description',
    date: new Date('2024-01-27 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 12,
    title: 'This is notification twelve',
    description: 'This is notification twelve description',
    date: new Date('2024-01-27 16:46:29.175+05:30'),
    isRead: true,
  },
  {
    id: 13,
    title: 'This is notification thirteen',
    description: 'This is notification thirteen description',
    date: new Date('2024-01-27 16:46:29.175+05:30'),
    isRead: true,
  },
]

const mockBankAccounts = [
  {
    id: 1,
    bankName: 'Bank One',
    accountNumber: '123456123',
    isDefault: true,
  },
  {
    id: 2,
    bankName: 'Bank Two',
    accountNumber: '123456456',
    isDefault: false,
  },
  {
    id: 3,
    bankName: 'Bank Three',
    accountNumber: '123456789',
    isDefault: false,
  },
]

const mockPaymentsHistory = [
  {
    date: `Dec 22, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    card: '•••• 1234',
    amount: `-$4.50`,
    method: `[Method]`,
  },
  {
    date: `Dec 22, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `+$88.50`,
    method: `[Method]`,
  },
  {
    date: `Dec 22, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    card: '•••• 1234',
    amount: `+$4.50`,
    method: `[Method]`,
  },
  {
    date: `Dec 20, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    card: '•••• 1234',
    amount: `-$4.50`,
    method: `[Method]`,
  },
  {
    date: `Dec 20, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$27.99`,
    method: `[Method]`,
  },
  {
    date: `Dec 20, 2023`,
    status: 'posted',
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    card: '•••• 1234',
    amount: `-$40.20`,
    method: `[Method]`,
  },
  {
    date: `Dec 18, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `+$18.26`,
    method: `[Method]`,
  },
  {
    date: `Dec 18, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$120.56`,
    method: `[Method]`,
  },
  {
    date: `Dec 18, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Dec 18, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum`,
    card: '•••• 1234',
    amount: `-$4.50`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `+$88.50`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    card: '•••• 1234',
    amount: `+$4.50`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    card: '•••• 1234',
    amount: `-$4.50`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$27.99`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    card: '•••• 1234',
    description: `Lorem ipsum`,
    amount: `-$40.20`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `+$18.26`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$120.56`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Nov 27, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$40.20`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor sit`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    card: '•••• 1234',
    description: `Lorem ipsum`,
    amount: `+$18.26`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$120.00`,
    method: `[Method]`,
  },
  {
    date: `Oct 10, 2023`,
    merchantName: `[Merchant name]`,
    description: `Lorem ipsum dolor`,
    card: '•••• 1234',
    amount: `-$320.00`,
    method: `[Method]`,
  },
]

export const mockChartCategories = [
  {
    name: '[Rule Name]',
    value: 23,
  },
  {
    name: '[Rule Name]',
    value: 23,
  },
  {
    name: '[Rule Name]',
    value: 10,
  },
  {
    name: '[Rule Name]',
    value: 10,
  },
  {
    name: 'Remaining Categories',
    value: 30,
  },
]

const rewardsActivityRowContents = [
  {
    date: `Dec 22, 2023`,
    description: `Statefarm`,
    color: 'blue',
    rewards: '3x - Insurance',
    points: `+963`,
  },
  {
    date: `Dec 22, 2023`,
    description: `Hoffman Auto Sales`,
    color: 'pink',
    rewards: '10,000 - Hoffman]',
    points: `+10,000`,
  },
  {
    date: `Dec 22, 2023`,
    description: `Amazon gift card`,
    color: 'pink',
    rewards: '',
    points: `-1,000`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Lorem ipsum`,
    color: 'green',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Lorem ipsum`,
    color: 'pink',
    rewards: '[# - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Statement Credit`,
    color: 'blue',
    rewards: '',
    points: `-[Data]`,
  },
  {
    date: `Dec 18, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Statement Credit`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `-[Data]`,
  },
  {
    date: `Dec 18, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Statement Credit`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `-[Data]`,
  },
  {
    date: `Dec 18, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Nov 27, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 22, 2023`,
    description: `Statefarm`,
    color: 'blue',
    rewards: '3x - Insurance',
    points: `+963`,
  },
  {
    date: `Dec 22, 2023`,
    description: `Hoffman Auto Sales`,
    color: 'pink',
    rewards: '10,000 - Hoffman]',
    points: `+10,000`,
  },
  {
    date: `Dec 22, 2023`,
    description: `Amazon gift card`,
    color: 'pink',
    rewards: '',
    points: `-1,000`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Lorem ipsum`,
    color: 'green',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Lorem ipsum`,
    color: 'pink',
    rewards: '[# - Rule Name]',
    points: `+[Data]`,
  },
  {
    date: `Dec 20, 2023`,
    description: `Statement Credit`,
    color: 'blue',
    rewards: '',
    points: `-[Data]`,
  },
  {
    date: `Dec 18, 2023`,
    description: `Lorem ipsum`,
    color: 'blue',
    rewards: '[#x - Rule Name]',
    points: `+[Data]`,
  },
]

const mockUserTwostepVerifyMethodData = {
  id: '728ed52f',
  type: 'text',
  contactNumber: '123-456-5978',
}

export const userDigitalWalletAccountData = []

export const disputeHistory = [
  {
    id: '1',
    merchant: 'Electronics',
    description: 'Purchase of a laptop',
    amount: '1200.50',
    transactionDate: 'Dec 1, 2022',
    disputeFilledDate: 'Dec 2, 2022',
    status: 'activeDispute',
  },
  {
    id: '2',
    merchant: ' Groceries',
    description: 'Weekly grocery shopping',
    amount: '75.20',
    transactionDate: 'Dec 3, 2022',
    disputeFilledDate: 'Dec 4, 2022',
    status: 'completeDispute',
  },
  {
    id: '3',
    merchant: 'FastFix Auto',
    description: 'Car repair services',
    amount: '350.75',
    transactionDate: 'Dec 5, 2022',
    disputeFilledDate: 'Dec 6, 2022',
    status: 'cancelledDispute',
  },
  {
    id: '4',
    merchant: 'Clothing',
    description: 'Purchase of clothing items',
    amount: '200.00',
    transactionDate: 'Dec 7, 2022',
    disputeFilledDate: 'Dec 8, 2022',
    status: 'declinedDispute',
  },
]

export const activeDisputes = []

export const verifyDocumentTypes = [
  {
    value: '1',
    label: 'Passport',
  },
  {
    value: '2',
    label: 'Drivers License',
  },
  {
    value: '3',
    label: 'Bill',
  },
]

export const supportFAQData = [
  {
    id: '1',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '2',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '3',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '4',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '5',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '6',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '7',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
  {
    id: '8',
    question: 'Lorem ipsum dolor sit amet?',
    answer:
      'Lorem ipsum dolor sit amet consectetur. Adipiscing et ipsum turpis augue morbi cras velit in risus. Senectus dignissim quam sed lobortis ultrices sed sodales. Mi rhoncus scelerisque.',
  },
]
export const eligiblePurchasesDetaileForVehicleExpenses = [
  {
    date: 'Dec 22, 2023',
    description: 'Statefarm',
    amount: '$500.00',
    points: 5000,
  },
  {
    date: 'Dec 22, 2023',
    description: 'Hoffman Auto Sales',
    amount: '$127.55',
    points: 1280,
  },
  {
    date: 'Dec 22, 2023',
    description: 'Gas Stations X',
    amount: '$85.95',
    points: 860,
  },
  {
    date: 'Dec 22, 2023',
    description: 'World Auto',
    amount: '$45.23',
    points: 450,
  },
]
const reviewTermsAndConditionsDocs = [
  {
    name: 'Document name',
    link: 'https://drive.google.com/file/d/1JSEGUn4L_gAvzT_O5ziJJcI3N0jUsHK3/view?usp=drive_link',
    downloadName: 'document_name_1.pdf',
  },
  {
    name: 'Document name',
    link: 'https://drive.google.com/file/d/1Ow1PPjM2xS5un64Hir8JrCj5q9S2BWeE/view?usp=drive_link',
    downloadName: 'document_name_2.pdf',
  },
  {
    name: 'Document name',
    link: 'https://drive.google.com/file/d/19IGNMT0TNnkV6rbyvTJYIR7V8lBl37WN/view?usp=drive_link',
    downloadName: 'document_name_3.pdf',
  },
]

export default {
  mockSearchData,
  mockCreditCardData,
  mockRecentTransactionsData,
  transactionOriginalRowContents,
  statementsPdfTitle,
  mockNotifications,
  mockBankAccounts,
  mockPaymentsHistory,
  mockChartCategories,
  rewardsActivityRowContents,
  mockUserTwostepVerifyMethodData,
  userDigitalWalletAccountData,
  disputeHistory,
  activeDisputes,
  verifyDocumentTypes,
  supportFAQData,
  eligiblePurchasesDetaileForVehicleExpenses,
  reviewTermsAndConditionsDocs,
}
