import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from '@/components/ui/dropdown-menu'

import { Icons } from '@/theme/Images'
import PropTypes from 'prop-types'
import { ROUTE_ACCOUNT } from '@/routes/paths'
import { useAuth } from '../../contexts/auth.context'
import { useNavigate } from 'react-router'
import { useState } from 'react'
import { ROUTE_ACCOUNT_MANAGE_CARD } from '../../routes/paths'

const ProfileDropdownMenu = () => {
  const navigate = useNavigate()
  const { user, logout } = useAuth()
  const [isOpen, setIsOpen] = useState(false)

  return (
    <DropdownMenu onOpenChange={d => setIsOpen(d)} modal={true} open={isOpen} className="w-[240px]">
      <DropdownMenuTrigger asChild>
        <img src={Icons.userIcon} alt="user image" />
      </DropdownMenuTrigger>
      <DropdownMenuContent className="bg-white text-black border-dGray-300 border-1 rounded-lg mt-6 w-[240px] h-[210px] absolute top-[-10px] right-[-5vw] md:right-[-1.3vw]">
        <div className="flex flex-row h-[50px] px-4 py-3 items-center pt-5">
          <img src={Icons.avatarIcon} alt="user image" className="w-10 h-10 object-cover hidden md:flex" />
          <div className="flex flex-col justify-center items-start w-auto pl-4">
            <p className="font-semibold text-sm text-dGray-700 truncate">
              {user?.customer?.firstName} {user?.customer?.lastName}
            </p>
            <div className="w-[140px] overflow-hidden">
              <p className="font-normal text-sm text-dGray-600 truncate">{user?.customer?.email}</p>
            </div>
          </div>
        </div>
        <DropdownMenuSeparator className="bg-dGray-200 " />
        <DropdownMenuItem
          className={'text-dGray-300 text-md py-3 px-4 cursor-pointer'}
          onClick={() => navigate(ROUTE_ACCOUNT + ROUTE_ACCOUNT_MANAGE_CARD)}
        >
          <div className="flex flex-row justify-start items-center">
            <img src={Icons.userIcon} alt="icon" height={16} width={16} />
            <span className="text-dGray-700 font-medium text-sm ml-2">Account</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator className="bg-dGray-200" />
        <DropdownMenuItem
          className={'text-dGray-300 text-md py-3 px-4 cursor-pointer'}
          //NOTE: On click for ROUTE_SUPPORT could be used if the requirement changes
          // onClick={() => navigate(ROUTE_SUPPORT)}
          onClick={() => window.open('https://fastenrewards.zendesk.com/hc/en-us', '_blank')}
        >
          <div className="flex flex-row justify-start items-center">
            <img src={Icons.helpBoldIcon} alt="icon" height={16} width={16} />
            <span className="text-dGray-700 font-medium text-sm ml-2">Support</span>
          </div>
        </DropdownMenuItem>
        <DropdownMenuSeparator className="bg-dGray-200" />
        <DropdownMenuItem className={'text-dGray-300 text-md py-3 px-4 cursor-pointer'} onClick={() => logout()}>
          <div className="flex flex-row justify-start items-center">
            <img src={Icons.logoutIcon} alt="icon" height={16} width={16} />
            <span className="text-dGray-700 font-medium text-sm ml-2">Log out</span>
          </div>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  )
}

ProfileDropdownMenu.propTypes = {
  data: PropTypes.object,
}

export default ProfileDropdownMenu
