import { useState } from 'react'

import { Skeleton } from '@/components/ui/skeleton'
import { Progress, Button } from '@/components'
import { ROUTE_REWARDS, ROUTE_REWARDS_BENEFITS } from '@/routes/paths'
import { useNavigate } from 'react-router-dom'

const RewardsSkeleton = () => {
  const navigate = useNavigate()
  const [showRewards, setShowRewards] = useState(false)

  return (
    <div className="flex flex-col items-start md:p-5 px-5 md:mt-2">
      <div className="flex flex-row w-full justify-between items-center">
        <div className="flex flex-col items-start">
          <p className="text-lg font-semibold text-dGray-900">Points Balance</p>
          <Skeleton className="w-24 h-10"></Skeleton>
        </div>
        <div
          className="flex md:hidden flex-row justify-end items-center cursor-pointer"
          onClick={() => setShowRewards(prev => !prev)}
        >
          <p className="text-md font-semibold">{`Show ${showRewards ? 'less' : 'more'}`}</p>
        </div>
      </div>
      <div
        className={`w-full mt-5 ${showRewards ? 'flex h-max' : 'hidden h-0'} h-max md:flex flex-col overflow-hidden`}
      >
        <div className="w-full bg-[#6F909E] bg-opacity-5 rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <Skeleton className="w-24 h-3"></Skeleton>
              <Skeleton className="w-12 h-3"></Skeleton>
            </div>
            <Progress
              value={0}
              className="h-[8px] bg-[#6F909E] bg-opacity-40"
              indicatorColor="bg-[#6F909E] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
        <div className="w-full bg-[#FFA500] bg-opacity-5 md:rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <Skeleton className="w-12 h-3"></Skeleton>
              <Skeleton className="w-24 h-3"></Skeleton>
            </div>
            <Progress
              value={0}
              className="h-[8px] bg-[#FFA500] bg-opacity-40"
              indicatorColor="bg-[#FFA500] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
        <div className="w-full bg-[#16374E] bg-opacity-5 md:rounded-lg flex flex-row pt-4 pb-4 pr-4 mb-3">
          <div className="ml-5 w-full flex flex-col h-[35px] justify-between item-between">
            <div className="w-full flex justify-between items-center">
              <Skeleton className="w-12 h-3"></Skeleton>
              <Skeleton className="w-24 h-3"></Skeleton>
            </div>
            <Progress
              value={0}
              className="h-[8px] bg-[#16374E] bg-opacity-40"
              indicatorColor="bg-[#16374E] rounded-full"
              data-testid="progress-bar"
            />
          </div>
        </div>
      </div>
      <Button
        className="w-full bg-brand-600 hover:bg-brand-700 my-4 md:my-0 text-sm"
        onClick={() => navigate(`/${ROUTE_REWARDS}${ROUTE_REWARDS_BENEFITS}`)}
      >
        Explore Rewards
      </Button>
    </div>
  )
}

export default RewardsSkeleton
