import { MONTHS } from '@/data/constants'
import { toZonedTime } from 'date-fns-tz'

//Format notification date and time
const formatNotificationTime = time => {
  const now = new Date()
  const difference = now - time
  if (difference <= 60000) {
    return 'now'
  } else if (difference > 1000 && difference <= 3600000) {
    let mins = difference / 60000
    return `${Math.round(mins)} mins ago`
  } else if (difference > 3600000 && difference <= 86400000) {
    let hrs = difference / 3600000
    return `${Math.round(hrs)} hrs ago`
  } else {
    return `${MONTHS[time?.getMonth()]} ${time?.getDate()}`
  }
}

//Converting UTC date times into ET date times
const convertUTCToET = utcTime => {
  if (utcTime) {
    const etDate = toZonedTime(utcTime, 'America/New_York')
    return etDate
  }
}

export { formatNotificationTime, convertUTCToET }
