import { useLocation, useNavigate } from 'react-router-dom'
import { useAuth } from '@/contexts/auth.context'
import { useEffect } from 'react'
import { ROUTE_APPLICATION_RESULT, ROUTE_EMAIL_VERIFICATION, ROUTE_HOME, ROUTE_SIGN_IN, ROUTE_SIGN_UP } from './paths'
import { EMAIL_VERIFICATION_PROMPT, EMAIL_VERIFIED, EMAIL_VERIFICATION_EXPIRED } from '@/data/constants'
import { setVerificationDetails } from '@/storage'
import { confirmSignUp } from 'aws-amplify/auth'
import { useState } from 'react'
import LoadingScreen from '../components/loading-screen'

// eslint-disable-next-line react-refresh/only-export-components
export const usePersistRoute = () => {
  const location = useLocation()

  useEffect(() => {
    // Persist the current route on any route change
    localStorage.setItem('lastRoute', location.pathname)
  }, [location])
}

// eslint-disable-next-line react/prop-types
export const RedirectAuthenticated = ({ children }) => {
  const { authenticated } = useAuth()
  const navigate = useNavigate()
  const { user } = useAuth()

  useEffect(() => {
    if (authenticated) {
      if (!user.hasCustomer()) {
        // If the user is authenticated but does not have a customer profile, go to sign-up page
        navigate(ROUTE_SIGN_UP, { replace: true })
      } else if (user.isBannerDismissed()) {
        // If the user has a customer profile, redirect to the last route or home page
        navigate('/' + ROUTE_HOME, { replace: true })
      } else {
        // If the user has a customer profile, redirect to the last route or home page
        navigate(ROUTE_APPLICATION_RESULT, { replace: true })
      }
    } else if (location.pathname !== ROUTE_SIGN_IN) {
      navigate(ROUTE_SIGN_IN, { replace: true })
    } else {
      // If the user is not authenticated, redirect to sign-in page
      navigate(ROUTE_SIGN_IN, { replace: true })
    }
  }, [authenticated, navigate, user])

  return authenticated ? null : children
}

export const ProtectedRoute = ({ children }) => {
  const { authenticated, user } = useAuth()
  const navigate = useNavigate()
  const location = useLocation()

  usePersistRoute()

  useEffect(() => {
    const lastRoute = localStorage.getItem('lastRoute')
    const isInvalidRoute = lastRoute === '/' || lastRoute === ROUTE_SIGN_IN

    if (location.pathname === ROUTE_HOME) {
      localStorage.setItem('applicationResultsVisited', true) // Mark that they visited application-results
    }
    if (!user.hasCustomer() && localStorage.getItem('VerificationDetails')) {
      navigate(ROUTE_SIGN_UP)
    } else if (authenticated) {
      const applicationResultsVisited = localStorage.getItem('applicationResultsVisited')

      // Prevent going back to application-results once home has been visited
      if (applicationResultsVisited && location.pathname === ROUTE_APPLICATION_RESULT) {
        navigate('/' + ROUTE_HOME, { replace: true })
      } else if (!isInvalidRoute && lastRoute === location.pathname) {
        navigate(lastRoute, { replace: true })
      } else if (user.isBannerDismissed()) {
        navigate('/' + ROUTE_HOME, { replace: true })
      } else {
        navigate(ROUTE_APPLICATION_RESULT, { replace: true })
      }
    } else {
      navigate(ROUTE_SIGN_IN, { replace: true })
    }
  }, [authenticated, user, navigate, location.pathname])

  return authenticated && user.hasCustomer() ? children : navigate(ROUTE_SIGN_UP)
}

// eslint-disable-next-line react/prop-types
export const ProtectedSignUp = ({ children }) => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const lastRoute = localStorage.getItem('lastRoute')

  const searchParams = new URLSearchParams(location.search)
  const code = searchParams.get('code')
  const email = searchParams.get('email')

  const [loading, setLoading] = useState(true) // Added loading state

  useEffect(() => {
    const handleSignUpConfirmation = async () => {
      if (code && email) {
        try {
          const { isSignUpComplete } = await confirmSignUp({
            username: email,
            confirmationCode: code,
          })

          if (isSignUpComplete) {
            setVerificationDetails({ status: EMAIL_VERIFIED })
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFIED, replace: true })
          } else {
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFICATION_PROMPT, replace: true })
          }
        } catch (error) {
          if (error.message === 'Invalid code provided, please request a code again.') {
            setVerificationDetails({ status: EMAIL_VERIFICATION_EXPIRED })
            navigate(ROUTE_EMAIL_VERIFICATION, { state: EMAIL_VERIFICATION_EXPIRED, replace: true })
          } else {
            console.error(error)
          }
        } finally {
          setLoading(false) // Set loading to false after the process is done
        }
      } else {
        if (!user.hasCustomer()) {
          navigate(ROUTE_SIGN_UP, { replace: true })
        } else {
          console.log('User already has a customer profile', user.hasCustomer() + '....')
          navigate(lastRoute || '/' + ROUTE_HOME, { replace: true })
        }
        setLoading(false) // Set loading to false when not confirming sign-up
      }
    }

    handleSignUpConfirmation()
  }, [lastRoute, navigate, user, code, email])

  // Show a loading spinner or null while loading
  if (loading) {
    return <LoadingScreen />
  }

  return user.hasCustomer() ? null : children
}

//TODO:Application Result page protection if any {!isCardApplicationSubmitted}

export const ProtectedApplicationResult = ({ children }) => {
  const { authenticated } = useAuth()
  const navigate = useNavigate()
  useEffect(() => {
    if (!authenticated) {
      navigate(ROUTE_SIGN_IN, { replace: true })
    }
  }, [authenticated, navigate])

  return !authenticated ? ROUTE_SIGN_IN : children
}
