import PropTypes from 'prop-types'
import { useClickAway } from '@uidotdev/usehooks'

import { Icons } from '@/theme/Images'
import { dummyData } from '@/data'
import { formatNotificationTime } from '@/utils/date-format-util'
import './navbar.css'

const NotificationPanel = ({ showPanel, toggleNotificationPanel }) => {
  const notificationRef = useClickAway(() => {
    toggleNotificationPanel(false)
  })
  const notifications = dummyData?.mockNotifications

  const unreadDot = () => {
    return <div className="h-2 w-2 rounded-full bg-success-500"></div>
  }

  const notificationItem = item => {
    return (
      <div key={item.id} className="flex flex-row justify-between items-center my-5 cursor-pointer">
        <div className="flex flex-row justify-start items-center">
          <div className="flex flex-row justify-center items-center rounded-full bg-dGray-100 border-[1px] border-dGray-300 h-12 w-12">
            <img src={Icons.bellOutlinedIcon} alt="bell icon" />
          </div>
          <div className="ml-3 max-w-[300px]">
            <div className="flex flex-row items-end">
              <p
                className={`${item.isRead ? 'font-medium' : 'font-bold'} text-sm text-dGray-700 max-w-[200px] truncate`}
              >
                {item.title}
              </p>
              <span className="font-normal text-xs text-dGray-600 ml-2">{formatNotificationTime(item?.date)}</span>
            </div>
            <p
              className={`${item.isRead ? 'font-normal' : 'font-medium'} text-sm text-dGray-600 max-w-[250px] truncate`}
            >
              {item.description}
            </p>
          </div>
        </div>
        {!item.isRead ? unreadDot() : null}
      </div>
    )
  }

  return (
    <div
      className={`fixed right-0 top-0 z-50 transition-all duration-300 ease-in-out bg-white border-[1px] border-dGray-200 w-[100vw] md:w-[400px] h-[100vh] shadow-xl ${
        showPanel ? 'translate-x-0' : 'translate-x-full'
      }`}
      ref={notificationRef}
      data-testid="notification-panel"
    >
      <div>
        <div className="flex flex-row justify-between items-start px-5 pt-5">
          <p className="text-xl font-semibold text-dGray-900">Notifications</p>
          <img
            src={Icons.closeIcon}
            alt="close-icon"
            onClick={() => toggleNotificationPanel(false)}
            className="cursor-pointer"
          />
        </div>
        <div className="overflow-y-auto overflow-hidden max-h-[94vh] dropdown-suggestions px-5 pt-1">
          {notifications?.map(item => notificationItem(item))}
        </div>
      </div>
    </div>
  )
}

NotificationPanel.propTypes = {
  showPanel: PropTypes.bool,
  toggleNotificationPanel: PropTypes.func,
}

export default NotificationPanel
