/* eslint-disable react-hooks/exhaustive-deps */
import PropTypes from 'prop-types'
import { DropDownContainer, InputWithIcon } from '@/components'
import { useEffect, useState } from 'react'

/**
 * Component for rendering a dropdown with an "Other" option that reveals an input field when selected.
 * @param {object} props - The props object containing dropdownProps, inputProps, options, formik, and fieldInfo.
 * @returns {JSX.Element} - JSX for rendering the dropdown with other option.
 */
const DropDownWithOtherOption = ({ dropdownProps, inputProps, options, formik, fieldInfo, disabled }) => {
  const [showOtherOption, setShowOtherOption] = useState(false)

  const updateFieldValues = (key, value) => {
    fieldInfo ? formik.setFieldValue(fieldInfo.key, updateValueArray(key, value)) : formik.setFieldValue(key, value)
  }

  const dropdownValue = fieldInfo
    ? formik.values[fieldInfo.key][fieldInfo.index][dropdownProps.id]
    : formik.values[dropdownProps.id]

  const fieldError =
    fieldInfo && formik.errors[fieldInfo.key] && formik.errors[fieldInfo.key][fieldInfo.index]
      ? formik.errors[fieldInfo.key][fieldInfo.index][inputProps.id]
      : formik.errors[inputProps.id]

  const fieldTouched =
    fieldInfo && formik.touched[fieldInfo.key] && formik.touched[fieldInfo.key][fieldInfo.index]
      ? formik.touched[fieldInfo.key][fieldInfo.index][inputProps.id]
      : formik.touched[inputProps.id]

  // when "other" option is selected in the dropdown, show text field
  useEffect(() => {
    const isOther = dropdownValue === 'other' || dropdownValue === '0'
    setShowOtherOption(isOther)
    if (!isOther) updateFieldValues(inputProps.id, '')
  }, [dropdownValue])

  const updateValueArray = (id, value) => {
    const arr = formik.values[fieldInfo.key]
    arr[fieldInfo.index][id] = value
    return arr
  }

  const formikProps = fieldInfo
    ? {
        value: formik.values[fieldInfo.key][fieldInfo.index][inputProps.id],
        onChange: event => formik.setFieldValue(fieldInfo.key, updateValueArray(inputProps.id, event.target.value)),
        onBlur: () => formik.setFieldTouched(`${fieldInfo.key}[${fieldInfo.index}].${inputProps.id}`, true),
      }
    : {
        ...formik.getFieldProps(inputProps.id),
        onBlur: () => formik.setFieldTouched(inputProps.id, true),
      }

  return (
    <div className="flex flex-col">
      <DropDownContainer
        key={dropdownProps.id}
        label={dropdownProps.label}
        options={options}
        placeholder={dropdownProps.placeholder}
        id={dropdownProps.id}
        formik={formik}
        fieldInfo={fieldInfo}
        disabled={disabled}
      />
      {showOtherOption && !disabled ? (
        <InputWithIcon
          data-testid="dropdown-other-input"
          id={inputProps.id}
          placeholder={inputProps.placeholder}
          onBlur={formikProps.onBlur}
          helpText={
            fieldTouched && fieldError ? <p className="text-sm font-normal text-[--error-500]">{fieldError}</p> : null
          }
          {...formikProps}
        />
      ) : null}
    </div>
  )
}

DropDownWithOtherOption.propTypes = {
  dropdownProps: PropTypes.shape({
    label: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  inputProps: PropTypes.shape({
    placeholder: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
  }).isRequired,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.string,
      label: PropTypes.string,
    })
  ).isRequired,
  formik: PropTypes.object.isRequired,
  fieldInfo: PropTypes.shape({ index: PropTypes.number, key: PropTypes.string }),
  disabled: PropTypes.bool,
}

export { DropDownWithOtherOption }
