function FailureAlertIcon() {
  return (
    <div className="relative flex flex-row w-7 h-7 justify-center items-center ml-1 mt-1">
      <div className="absolute rounded-full border-[3px] border-error-700 opacity-10 h-[40px] w-[40px] p-4" />
      <div className="absolute rounded-full border-[3px] border-error-500 opacity-30 h-[30px] w-[30px] p-3 gap-20 m-1" />
      <div className="absolute pl-3 pt-3">
        <svg
          className="relative"
          width="52"
          height="52"
          viewBox="0 0 50 50"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M19.0003 15.6667V19.0001M19.0003 22.3334H19.0087M27.3337 19.0001C27.3337 23.6025 23.6027 27.3334 19.0003 27.3334C14.398 27.3334 10.667 23.6025 10.667 19.0001C10.667 14.3977 14.398 10.6667 19.0003 10.6667C23.6027 10.6667 27.3337 14.3977 27.3337 19.0001Z"
            stroke="#D92D20"
            strokeWidth="2"
            strokeLinecap="round"
            strokeLinejoin="round"
          />
        </svg>
      </div>
    </div>
  )
}

export default FailureAlertIcon
