import { useMutation } from '@tanstack/react-query'
import { useAuth } from '@/contexts/auth.context'
import { commonApi } from '../../api'

export const useDismissFirstLoginModal = () => {
  const { user, setUser } = useAuth()

  return useMutation({
    mutationKey: ['dismiss-first-login-modal'],
    mutationFn: commonApi.dismissFirstLoginModal,
    onSuccess: ({ preferences }) => {
      if (preferences?.firstLoginModalDismissed) setUser({ ...user, customer: { ...user.customer, preferences } })
    },
    onError: () =>
      setUser({
        ...user,
        customer: { ...user.customer, preferences: { ...user.customer.preferences, firstLoginModalDismissed: true } },
      }),
  })
}
