import { useNavigate, useLocation } from 'react-router-dom'
import { useAuth } from '@/contexts/auth.context'
import { StaticAnnouncement, StaticAnnouncementAction } from '@/components/static-announcement'
import { Icons } from '@/theme/Images'
import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_MANAGE_CARD,
  ROUTE_ACCOUNT_INFO_AND_SECURITY,
  ROUTE_ACCOUNT_MORE,
  ROUTE_HOME,
  ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
  ROUTE_TRANSACTIONS_STATEMENTS,
  ROUTE_PAYMENTS_AUTO_PAY,
  ROUTE_PAYMENTS_MAKE_PAYMENT,
  ROUTE_PAYMENTS_PAYMENT_HISTORY,
  ROUTE_PAYMENTS_PAYMENT_METHODS,
  ROUTE_REWARDS_BENEFITS,
  ROUTE_REWARDS_MY_REWARDS,
  ROUTE_VEHICLES_MANAGE_VEHICLES,
} from '@/routes/paths'

export default function UnlockCardStaticBanner() {
  const navigate = useNavigate()
  const { pathname } = useLocation()
  const { user } = useAuth()
  const lockUnlockCardDetails = user.getLockUnlockCardDetails()

  const isBannerRoute = [
    ROUTE_ACCOUNT,
    ROUTE_ACCOUNT_MANAGE_CARD,
    ROUTE_ACCOUNT_INFO_AND_SECURITY,
    ROUTE_ACCOUNT_MORE,
    ROUTE_HOME,
    ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
    ROUTE_TRANSACTIONS_STATEMENTS,
    ROUTE_PAYMENTS_AUTO_PAY,
    ROUTE_PAYMENTS_MAKE_PAYMENT,
    ROUTE_PAYMENTS_PAYMENT_HISTORY,
    ROUTE_PAYMENTS_PAYMENT_METHODS,
    ROUTE_REWARDS_BENEFITS,
    ROUTE_REWARDS_MY_REWARDS,
    ROUTE_VEHICLES_MANAGE_VEHICLES,
  ].includes(pathname)

  // TODO: Need to change the logic of showing the banner
  const isCardSuspended = lockUnlockCardDetails?.status === 'SUSPENDED'

  // banner should show if either the route is correct or the card is suspended
  const showUnlockCardBanner = (isBannerRoute || isCardSuspended) && lockUnlockCardDetails

  const bannerClass = [ROUTE_HOME, ROUTE_TRANSACTIONS_ALL_TRANSACTIONS, ROUTE_TRANSACTIONS_STATEMENTS].includes(
    pathname
  )
    ? 'md:px-[10%] md:pt-[2%] bg-bgGray md:p-0 px-0 py-6'
    : 'md:px-[10%] md:pt-[2%] bg-bgGray md:p-0 px-6 pt-6'

  return showUnlockCardBanner ? (
    <div className={bannerClass}>
      <div className="flex flex-col md:gap-8 gap-5">
        <StaticAnnouncement
          startIcon={Icons.grayNotificationIcon}
          text="Your card has been locked and we will not authorize any new transactions until you unlock it."
          action={
            <StaticAnnouncementAction
              text="Unlock card"
              handleClick={() => navigate(ROUTE_ACCOUNT + ROUTE_ACCOUNT_MANAGE_CARD)}
              disabled={pathname === '/' + ROUTE_ACCOUNT + ROUTE_ACCOUNT_MANAGE_CARD}
            />
          }
        />
      </div>
    </div>
  ) : null
}
