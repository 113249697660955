import { useEffect, useState } from 'react'

/**
 * Custom React hook to manage a countdown timer with a specified time limit.
 * @param {number} timeLimit - The initial time limit for the countdown timer in seconds.
 * @returns {[number, function]} - A tuple containing the current value of the timer
 * and a function to update the timer.
 */
export function useTimeout(timeLimit) {
  const [timer, setTimer] = useState(timeLimit)

  useEffect(() => {
    let intervalId

    // Start the timer if it's greater than 0
    if (timer > 0) {
      intervalId = setInterval(() => {
        // Decrement the timer by 1 second
        setTimer(prevTimer => prevTimer - 1)
      }, 1000)
    }

    // Clean up the interval when the component unmounts or when the timer changes
    return () => clearInterval(intervalId)
  }, [timer])

  return [timer, setTimer]
}
