import { Footer, NavBar } from '@/components/index'

import PropTypes from 'prop-types'
import UnlockCardStaticBanner from '@/pages/account-page/tabs/manage-card/unlock-card-static-banner.jsx'
import PlaidRecurringBanner from '../plaid-recurring-banner'
import useZendesk from '@/hooks/use-zendesk'

const PrivateLayout = ({ children }) => {
  //Use the custom hook
  useZendesk()

  return (
    <>
      <NavBar />
      <UnlockCardStaticBanner />
      <PlaidRecurringBanner />
      {children}
      <Footer />
    </>
  )
}

PrivateLayout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default PrivateLayout
