import PropTypes from 'prop-types'
import { useAuth } from '../../contexts/auth.context'
import { Images } from '@/theme/Images'
import CardDetailsDialog from './card-details-dialog'

const CreditCardContainer = () => {
  const { user } = useAuth()

  return (
    <div data-testid="left-column">
      <div className="h-[180px] overflow-hidden w-full relative ">
        <img
          src={Images.sidebarBgImage}
          className="sm:rounded-t-md absolute w-full h-full object-cover object-left-top"
          alt="sidebar image"
        />
      </div>
      <div className="flex flex-col w-full">
        <div className="flex flex-col items-start p-5">
          <p className="font-semibold text-2xl text-dGray-900">
            {user?.customer?.firstName} {user?.customer?.lastName}
          </p>
          <p className="text-md font-normal text-dGray-600">
            Fasten Rewards member since {new Date(user?.customer?.createdAt).getFullYear()}
          </p>
        </div>
        <div className="p-5 flex flex-col  md:border-b-dGray-200 md:border-b-[2px]">
          <img src={Images.creditCardMockupNew} />
          <CardDetailsDialog />
        </div>
      </div>
    </div>
  )
}
CreditCardContainer.propTypes = {
  data: PropTypes.object,
}

export default CreditCardContainer
