import PropTypes from 'prop-types'
import { cn } from '@/lib/utils'
import React from 'react'
import { HelpCircle } from 'lucide-react'
import { Tooltip, TooltipContent, TooltipProvider, TooltipTrigger } from '@radix-ui/react-tooltip'

const InputWithIcon = React.forwardRef(
  ({ icon, label, tooltipHead, tooltipBody, className, type, helpText, onBlur, touched, error, ...props }, ref) => {
    return (
      <div className="flex flex-col items-start self-stretch gap-1.5" data-testid="input-with-icon">
        <p className="text-sm font-medium text-[--gray-700]">{label}</p>
        <div
          className={cn(
            'flex min-h-10 items-center rounded-md border border-input bg-white pl-3 text-base w-full',
            error && touched ? 'border-[--error-300]' : '',
            className
          )}
          onBlur={onBlur}
        >
          {icon}
          <input
            ref={ref}
            type={type}
            className={`w-full placeholder:text-[--gray-500] placeholder:text-muted-foreground focus-visible:outline-none disabled:cursor-not-allowed disabled:opacity-50 ${
              icon ? 'p-2' : ''
            }`}
            {...props}
          />
          <div className="px-2">
            {tooltipHead || tooltipBody ? (
              <TooltipProvider>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <HelpCircle
                      className="w-4 h-4 cursor-pointer"
                      color="#98A2B3"
                      name="help-icon"
                      data-testid="help-icon"
                    />
                  </TooltipTrigger>
                  <TooltipContent className="mb-1 flex flex-col font-sans bg-[#101828] p-3 rounded-md max-w-80 gap-1">
                    <p className="text-white text-xs font-[600]">{tooltipHead}</p>
                    <p className="text-[#D0D5DD] text-xs font-[500]">{tooltipBody}</p>
                  </TooltipContent>
                </Tooltip>
              </TooltipProvider>
            ) : null}
          </div>
        </div>
        {touched && error && <p className="text-sm font-normal text-[--error-500]">{error}</p>}
        {helpText}
      </div>
    )
  }
)

InputWithIcon.displayName = 'InputWithIcon'

export { InputWithIcon }

InputWithIcon.propTypes = {
  icon: PropTypes.node,
  type: PropTypes.string,
  helpText: PropTypes.node,
  className: PropTypes.string,
  onBlur: PropTypes.func,
  touched: PropTypes.bool,
  error: PropTypes.string,
  id: PropTypes.string,
  label: PropTypes.string,
  tooltipHead: PropTypes.string,
  tooltipBody: PropTypes.string,
}
