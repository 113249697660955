import PropTypes from 'prop-types'
import { cn } from '@/lib/utils'

function InputFieldContainer({ label, children, className }) {
  return (
    <div className={cn('flex flex-col items-start self-stretch gap-1.5', className)}>
      <div className="flex flex-col items-start self-stretch gap-1.5">
        <p className="text-sm font-medium text-[--gray-700]">{label}</p>
        {children}
      </div>
    </div>
  )
}

InputFieldContainer.propTypes = {
  label: PropTypes.string,
  children: PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.element), PropTypes.element]),
  className: PropTypes.string,
}

export { InputFieldContainer }
