function FailureToastIcon() {
  return (
    <div className="relative flex flex-row w-8 h-8 justify-center items-center">
      <div className="absolute rounded-full border-[1.67px] border-error-700 opacity-10 h-[34px] w-[34px]" />
      <div className="absolute rounded-full border-[1.67px] border-error-700 opacity-40 h-[26px] w-[26px]" />

      <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10.0888 5.87478L5.92129 10.0423M5.92129 5.87478L10.0888 10.0423M14.9509 7.95853C14.9509 11.7946 11.8411 14.9044 8.00504 14.9044C4.16896 14.9044 1.0592 11.7946 1.0592 7.95853C1.0592 4.12245 4.16896 1.0127 8.00504 1.0127C11.8411 1.0127 14.9509 4.12245 14.9509 7.95853Z"
          stroke="#B42318"
          strokeWidth="2"
          strokeLinecap="round"
          strokeLinejoin="round"
        />
      </svg>
    </div>
  )
}

export default FailureToastIcon
