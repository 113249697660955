/* eslint-disable react-refresh/only-export-components */

import {
  ROUTE_ACCOUNT,
  ROUTE_ACCOUNT_INFO_AND_SECURITY,
  ROUTE_ACCOUNT_MANAGE_CARD,
  ROUTE_ACCOUNT_MORE,
  ROUTE_APPLICATION_RESULT,
  ROUTE_EMAIL_VERIFICATION,
  ROUTE_FORGOT_PASSWORD,
  ROUTE_HOME,
  ROUTE_PAYMENTS,
  ROUTE_PAYMENTS_AUTO_PAY,
  ROUTE_PAYMENTS_MAKE_PAYMENT,
  ROUTE_PAYMENTS_PAYMENT_HISTORY,
  ROUTE_PAYMENTS_PAYMENT_METHODS,
  ROUTE_REWARDS,
  ROUTE_REWARDS_BENEFITS,
  ROUTE_REWARDS_MY_REWARDS,
  ROUTE_ROOT,
  ROUTE_SIGN_IN,
  ROUTE_SIGN_UP,
  ROUTE_SUPPORT,
  ROUTE_SUPPORT_CONTACT_US,
  ROUTE_SUPPORT_FAQ,
  ROUTE_TRANSACTIONS,
  ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
  ROUTE_TRANSACTIONS_STATEMENTS,
  ROUTE_VEHICLES,
  ROUTE_VEHICLES_MANAGE_VEHICLES,
} from './paths'
import { Suspense, lazy } from 'react'

import App from '@/App'
import ErrorPage from '@/pages/error-page'
import HomePage from '@/pages/home-page/home-page'
import SignInPage from '@/pages/sign-in-page'
import { createBrowserRouter } from 'react-router-dom'
import { signInLoader } from '@/api/sign-in-loader'
import { ProtectedApplicationResult, ProtectedRoute, ProtectedSignUp, RedirectAuthenticated } from './ProtectedRoute'
import LoadingScreen from '@/components/loading-screen'

const LazyForgotPasswordPage = lazy(() => import('@/pages/forgot-password-page'))
const LazyAccountPage = lazy(() => import('@/pages/account-page'))
const LazyAccountPageManageCard = lazy(() => import('@/pages/account-page/tabs/manage-card'))
const LazyAccountPageAccountInfoAndSecurity = lazy(() => import('@/pages/account-page/tabs/account-info-and-security'))
const LazyAccountPageMore = lazy(() => import('@/pages/account-page/tabs/more'))
const LazyCardApplication = lazy(() => import('@/pages/card-application-page'))
const LazyPaymentsPage = lazy(() => import('@/pages/payments-page/payments-page'))
const LazyPaymentsPageMakePayment = lazy(() => import('@/pages/payments-page/tabs/make-payment/make-payment'))
const LazyPaymentsPagePaymentHistory = lazy(() => import('@/pages/payments-page/tabs/payment-history/payment-history'))
const LazyPaymentsPageAutoPay = lazy(() => import('@/pages/payments-page/tabs/auto-pay/auto-pay'))
const LazyPaymentsPagePaymentMethods = lazy(() => import('@/pages/payments-page/tabs/payment-methods/payment-methods'))
const LazyRewardsPage = lazy(() => import('@/pages/rewards-page/rewards-page'))
const LazyRewardsPageBenefits = lazy(() => import('@/pages/rewards-page/tabs/benefits/benefits'))
const LazyRewardsPageMyRewards = lazy(() => import('@/pages/rewards-page/tabs/my-rewards/my-rewards'))

const LazyVehiclesPage = lazy(() => import('@/pages/vehicles-page/vehicles-page'))
const LazyVehiclesPageManageVehicles = lazy(() => import('@/pages/vehicles-page/tabs/manage-vehicles/manage-vehicles'))

const LazyTransactionsPage = lazy(() => import('@/pages/transactions-page/transactions-page.jsx'))
const LazyTransactionsPageAllTransactions = lazy(
  () => import('@/pages/transactions-page/tabs/all-transactions/transaction-table.jsx')
)
const LazyTransactionsPageStatements = lazy(
  () => import('@/pages/transactions-page/tabs/statements/statements-table.jsx')
)

const LazySupportPage = lazy(() => import('@/pages/support-page/support-page-index'))
const LazySupportPageFAQ = lazy(() => import('@/pages/support-page/tabs/faq/faq.jsx'))
const LazySupportPageContactUs = lazy(() => import('@/pages/support-page/tabs/contact-us/contact-us.jsx'))
const LazySubmissionResultPage = lazy(() => import('@/pages/application-result-page'))
const LazyEmailVerificationPage = lazy(() => import('@/pages/card-application-page/email-verification'))

export const router = queryClient => {
  return createBrowserRouter([
    {
      path: ROUTE_SIGN_UP,
      element: (
        <ProtectedSignUp>
          <Suspense
            fallback={
              <div className="h-[100vh]">
                <LoadingScreen />
              </div>
            }
          >
            <LazyCardApplication />
          </Suspense>
        </ProtectedSignUp>
      ),
    },
    {
      path: ROUTE_SIGN_IN,
      element: (
        <RedirectAuthenticated>
          <SignInPage />
        </RedirectAuthenticated>
      ),
    },
    // TODO:Need protection
    {
      path: ROUTE_FORGOT_PASSWORD,
      element: (
        <Suspense
          fallback={
            <div className="h-[100vh]">
              <LoadingScreen />
            </div>
          }
        >
          <LazyForgotPasswordPage />
        </Suspense>
      ),
    },
    {
      path: ROUTE_EMAIL_VERIFICATION,
      element: (
        <Suspense
          fallback={
            <div className="h-[100vh]">
              <LoadingScreen />
            </div>
          }
        >
          <LazyEmailVerificationPage />
        </Suspense>
      ),
    },
    // TODO: Move this into app's children
    {
      path: ROUTE_APPLICATION_RESULT,
      loader: signInLoader(queryClient),
      element: (
        <ProtectedApplicationResult>
          <Suspense
            fallback={
              <div className="h-[100vh]">
                <LoadingScreen />
              </div>
            }
          >
            <LazySubmissionResultPage />
          </Suspense>
        </ProtectedApplicationResult>
      ),
    },
    // The Protected paths
    {
      path: ROUTE_ROOT,
      element: (
        <ProtectedRoute>
          <App />
        </ProtectedRoute>
      ),
      errorElement: <ErrorPage />,
      children: [
        {
          path: ROUTE_HOME,
          element: <HomePage />,
        },
        {
          path: ROUTE_ACCOUNT,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazyAccountPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_ACCOUNT_MANAGE_CARD,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyAccountPageManageCard />
                </Suspense>
              ),
            },
            {
              path: ROUTE_ACCOUNT_INFO_AND_SECURITY,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyAccountPageAccountInfoAndSecurity />
                </Suspense>
              ),
            },
            {
              path: ROUTE_ACCOUNT_MORE,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyAccountPageMore />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTE_PAYMENTS,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazyPaymentsPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_PAYMENTS_MAKE_PAYMENT,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyPaymentsPageMakePayment />
                </Suspense>
              ),
            },
            {
              path: ROUTE_PAYMENTS_PAYMENT_HISTORY,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyPaymentsPagePaymentHistory />
                </Suspense>
              ),
            },
            {
              path: ROUTE_PAYMENTS_AUTO_PAY,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyPaymentsPageAutoPay />
                </Suspense>
              ),
            },
            {
              path: ROUTE_PAYMENTS_PAYMENT_METHODS,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyPaymentsPagePaymentMethods />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTE_REWARDS,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazyRewardsPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_REWARDS_BENEFITS,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyRewardsPageBenefits />
                </Suspense>
              ),
            },
            {
              path: ROUTE_REWARDS_MY_REWARDS,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyRewardsPageMyRewards />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTE_VEHICLES,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazyVehiclesPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_VEHICLES_MANAGE_VEHICLES,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyVehiclesPageManageVehicles />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTE_TRANSACTIONS,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazyTransactionsPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_TRANSACTIONS_ALL_TRANSACTIONS,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyTransactionsPageAllTransactions />
                </Suspense>
              ),
            },
            {
              path: ROUTE_TRANSACTIONS_STATEMENTS,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazyTransactionsPageStatements />
                </Suspense>
              ),
            },
          ],
        },
        {
          path: ROUTE_SUPPORT,
          element: (
            <Suspense
              fallback={
                <div className="h-[100vh]">
                  <LoadingScreen />
                </div>
              }
            >
              <LazySupportPage />
            </Suspense>
          ),
          children: [
            {
              path: ROUTE_SUPPORT_FAQ,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazySupportPageFAQ />
                </Suspense>
              ),
            },
            {
              path: ROUTE_SUPPORT_CONTACT_US,
              element: (
                <Suspense
                  fallback={
                    <div className="h-[100vh]">
                      <LoadingScreen />
                    </div>
                  }
                >
                  <LazySupportPageContactUs />
                </Suspense>
              ),
            },
          ],
        },
      ],
    },
  ])
}
