import { useState } from 'react'
import PropTypes from 'prop-types'

import { Icons } from '@/theme/Images'
import { Button } from '@/components'

const Announcement = ({ startIcon, headLine, subText, action }) => {
  const [isOpen, setIsOpen] = useState(true)

  //Handle announcement close
  const handleBannerClose = e => {
    e.stopPropagation()
    setIsOpen(false)
  }

  return isOpen ? (
    <>
      <div className="hidden md:block" data-testid="custom-announcement">
        <div className="rounded-xl h-[72px] w-[100%] border-dGray-300 bg-white border-[1px] shadow-[0px_15px_30px_-20px_rgba(0,0,0,0.5)] flex items-center justify-between p-3">
          <div className="flex items-center justify-start">
            {startIcon ? (
              <div className="h-[48px] w-[48px] rounded-lg border-gray-300 border-[1px] flex shadow items-center justify-center">
                <img src={startIcon} alt="announcement icon" />
              </div>
            ) : null}
            {headLine ? <span className="font-semibold text-base ml-4 text-dGray-700">{headLine}</span> : null}
            {subText && <span className="font-regular text-base ml-2 text-dGray-600">{subText}</span>}
          </div>
          <div className="flex items-center justify-center">
            {action ? action : null}
            <img
              src={Icons.closeIcon}
              className="mr-2 ml-4 hover:cursor-pointer"
              alt="close icon"
              onClick={handleBannerClose}
            />
          </div>
        </div>
      </div>

      {/* For mobile view announcement */}
      <div
        className={'md:hidden fixed z-10 bottom-0 w-[100vw] h-[100vh] flex flex-col justify-end overflow-hidden p-5'}
        data-testid="custom-announcement"
      >
        <div className="rounded-xl h-max w-full border-dGray-300 bg-white border-[1px] shadow-md flex flex-col items-center p-3">
          <div className="flex flex-row w-full items-start justify-between">
            {startIcon ? (
              <div className="h-[48px] w-[48px] rounded-lg border-gray-300 border-[1px] flex shadow items-center justify-center">
                <img src={startIcon} alt="icon" />
              </div>
            ) : null}
            <img
              src={Icons.closeIcon}
              className="ml-4 hover:cursor-pointer"
              alt="close icon"
              onClick={handleBannerClose}
            />
          </div>
          <div className="w-full my-5">
            {headLine ? <p className="font-semibold text-base text-start w-full text-dGray-700">{headLine}</p> : null}
            {subText ? <p className="font-regular text-base text-start w-full text-dGray-600">{subText}</p> : null}
          </div>
          {action ? action : null}
        </div>
      </div>
    </>
  ) : null
}

const AnnouncementAction = ({ text, handleClick }) => {
  return (
    <Button variant="outline" className="font-[600] hover:bg-dGray-100 text-dGray-700 text-md" onClick={handleClick}>
      {text}
    </Button>
  )
}

Announcement.propTypes = {
  startIcon: PropTypes.string,
  headLine: PropTypes.string,
  subText: PropTypes.string,
  buttonText: PropTypes.string,
  buttonFn: PropTypes.func,
  action: PropTypes.object,
}
AnnouncementAction.propTypes = {
  text: PropTypes.string,
  handleClick: PropTypes.func,
}

export { Announcement, AnnouncementAction }
