// import { useQuery } from '@tanstack/react-query'

import { bankAccountApi } from '../../api'
import { useAuthQuery } from '@/hooks/use-auth-query'

export function useGetRecurringPayment() {
  return useAuthQuery({
    queryKey: ['recurring-payment'],
    queryFn: bankAccountApi.getRecurringPayment,
  })
}
