import { useState, useEffect, useRef } from 'react'
import { useNavigate } from 'react-router'

import { Images } from '@/theme/Images'
import { routes } from '@/data'
import MobileNavbar from './mobile-nav-menu'
import './navbar.css'

import { dummyData } from '@/data'
import ProfileDropdownMenu from './profile-dropdown-menu'
import NotificationPanel from './notification-panel'

const NavBar = () => {
  const navigate = useNavigate()
  const pathname = location.pathname.split('/')[1]

  const searchInputRef = useRef(null)
  const dropDownRef = useRef(null)

  const [isSearchVisible, setIsSearchVisible] = useState(false)
  const [searchQuery, setSearchQuery] = useState('')
  const [suggestions, setSuggestions] = useState([])
  const [showNotificationPanel, setShowNotificationPanel] = useState(false)

  const toggleNotificationPanel = val => {
    setShowNotificationPanel(val)
  }

  // NOTE: Search & Notification icons hidden as they are not required in the current design

  //Commenting out search icon activation handler since the search functionality is being hidden.
  // const handleSearchActivation = () => {
  //   setIsSearchVisible(prev => !prev)
  // }

  //Handling outside click of search input
  const handleClickOutside = event => {
    if (!dropDownRef.current?.contains(event?.target) && !searchInputRef.current?.contains(event?.target)) {
      setIsSearchVisible(false)
      setSearchQuery('')
    }
  }

  useEffect(() => {
    if (searchInputRef?.current) {
      searchInputRef?.current?.focus()
    }
    document.addEventListener('mousedown', handleClickOutside)

    return () => {
      document.removeEventListener('mousedown', handleClickOutside)
    }
  }, [isSearchVisible])

  const mockFetchSearchData = e => {
    setSearchQuery(e.target.value)
    setSuggestions(
      dummyData.mockSearchData.filter(item => item.toLocaleLowerCase().includes(e.target.value.toLocaleLowerCase()))
    )
  }

  const handleSearchItemClick = item => {
    console.log(item)
    setSearchQuery('')
    setSuggestions([])
    setIsSearchVisible(false)
  }

  return (
    <div className="bg-brand h-[72px] w-full sticky top-0 z-20 px-[5%] md:px-[10%] flex flex-row flex-wrap justify-between items-center">
      <div className="flex flex-row justify-start items-center">
        <MobileNavbar
          searchQuery={searchQuery}
          handleSearch={mockFetchSearchData}
          suggestions={suggestions}
          handleSearchItemClick={handleSearchItemClick}
          dropDownRef={dropDownRef}
        />
        <img
          src={Images.fastenTextLogoWhite}
          alt="fasten text logo"
          className="w-[8rem] h-auto cursor-pointer"
          onClick={() => navigate('home')}
        />
        <ul className="hidden lg:flex flex-row items-center justify-start h-full px-8">
          {routes.map(item => (
            <li
              key={item?.name}
              className={`text-white text-md font-semibold px-3 py-2 ${
                pathname === item?.path.split('/')[0] ? 'bg-brand-600 rounded-md' : ''
              } cursor-pointer`}
              onClick={() => navigate(item?.path)}
            >
              {item?.name}
            </li>
          ))}
        </ul>
      </div>
      <div className="flex flex-row">
        {/* {isSearchVisible ? (
          <div className="relative px-3 hidden lg:block">
            {<img src={Icons.searchIcon} className="absolute top-[25%] left-5" />}
            <Input
              placeholder="Search"
              className="bg-brand px-8 text-[16px] border-brand-500 border-2 text-white rounded-lg"
              onChange={e => mockFetchSearchData(e)}
              ref={searchInputRef}
              value={searchQuery}
            />
            {suggestions && searchQuery?.length > 0 ? (
              <ul
                ref={dropDownRef}
                className="absolute max-h-72 overflow-hidden overflow-y-auto bg-white w-[93%] py-3 px-1 shadow-md rounded-lg dropdown-suggestions"
              >
                {suggestions?.map((item, index) => (
                  <li
                    key={index}
                    className="py-2 px-2 font-semibold cursor-pointer hover:bg-dGray-100 rounded-lg"
                    onClick={event => {
                      handleSearchItemClick(item)
                      event.stopPropagation()
                    }}
                  >
                    {item}
                  </li>
                ))}
              </ul>
            ) : null}
          </div>
        ) : null} */}

        {/* <img
          src={Icons.searchIcon}
          alt="search icon"
          data-testid="search-icon"
          className="mx-3 hidden md:block cursor-pointer"
          onClick={handleSearchActivation}
        /> */}
        {/* Notification icon hidden */}
        {/* <img
          src={Icons.bellIcon}
          alt="bell icon"
          className="mx-3 cursor-pointer"
          onClick={() => setShowNotificationPanel(true)}
        /> */}
        <NotificationPanel showPanel={showNotificationPanel} toggleNotificationPanel={toggleNotificationPanel} />
        <div className="bg-white h-[2.5rem] w-[2.5rem] rounded-full flex justify-center items-center ml-4 cursor-pointer">
          <ProfileDropdownMenu />
        </div>
      </div>
    </div>
  )
}

NavBar.propTypes = {}

export default NavBar
