import { getCurrentUserSessionID } from './cognito-identity-service-provider'

export function saveUserDetails(user) {
  const userID = getCurrentUserSessionID()
  localStorage.setItem(`AuthProvider.${userID}.user`, JSON.stringify(user))
}

export function getUserDetails() {
  const userID = getCurrentUserSessionID()
  const user = localStorage.getItem(`AuthProvider.${userID}.user`)
  return user ? JSON.parse(user) : null
}

export function removeUserDetails() {
  const userID = getCurrentUserSessionID()
  localStorage.removeItem('lastRoute') // Clear lastRoute on logout
  localStorage.removeItem(`AuthProvider.${userID}.user`)
  localStorage.removeItem(`VerificationDetails`)
}

export function setVerificationDetails(details) {
  localStorage.setItem(`VerificationDetails`, JSON.stringify(details))
}

export function getVerificationDetails() {
  const details = localStorage.getItem(`VerificationDetails`)
  return details ? JSON.parse(details) : null
}

export function isSocialLoginUser() {
  const userID = getCurrentUserSessionID()
  if (userID) {
    return userID.startsWith('google_') || userID.startsWith('signinwithapple_')
  }
  return false
}
