import AccountSummarySkeleton from './account-summary.skeleton'
import AutoPayIndicator from '../payments-page/components/auto-pay-indicator'
import { Button } from '@/components/index'
import { ExtendedTooltip } from '@/components'
import { Icons } from '@/theme/Images'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import { transformAmount } from '@/utils/string-format-utils'
import { useAccountSummary } from '../hooks/use-account-summary'
import { useState } from 'react'

// Define constants
const CURRUNTBALANCE = 'current-balance'
const AVAILABLECREDIT = 'available-credit'

const AccountSummary = () => {
  const [showBalances, setShowBalances] = useState(false)
  const [isHovered, setIsHovered] = useState('')

  const { isLoading: isAccountSummaryLoading, data: accountSummaryData } = useAccountSummary()

  const summaryData = accountSummaryData || {
    currentBalance: 0,
    availableCredit: 0,
    creditLimit: 0,
    lastStatement: 0,
    currencyCode: 'USD',
    currentDue: 0,
  }

  if (isAccountSummaryLoading) {
    return <AccountSummarySkeleton />
  }

  return (
    <div data-testid="right-column">
      <div className="top-content w-full border-b-dGray-200 border-b-[1px] px-5 py-8">
        <div className="w-full flex flex-col md:flex-row md:flex-wrap justify-between items-start">
          <div className="w-full md:w-auto flex flex-row md:flex-col justify-between md:justify-start items-center">
            <div className="flex flex-col justify-start items-start">
              <div className="w-full flex flex-row justify-start items-center">
                <p className="font-semibold text-lg leading-5 text-dGray-900" data-testid="abcd">
                  Current balance
                </p>
                <ExtendedTooltip
                  trigger={
                    <img
                      src={isHovered === CURRUNTBALANCE ? Icons.helpBoldIcon : Icons.helpIcon}
                      className="cursor-pointer ml-2"
                      width={13.5}
                      onMouseEnter={() => setIsHovered(CURRUNTBALANCE)}
                      onMouseLeave={() => setIsHovered('')}
                    />
                  }
                  title={'Current balance'}
                  content="This may not include all transactions such as your most recent transactions, pending authorizations or interest that's accumulated since your last statement date."
                />
              </div>
              <p className="font-semibold text-4xl mt-1 text-dGray-900" data-testid="balance-field">
                {transformAmount(summaryData.currentBalance)}
              </p>
            </div>
            <div
              className="flex md:hidden flex-row justify-end items-center cursor-pointer"
              onClick={() => setShowBalances(prev => !prev)}
            >
              <p className="text-md font-semibold">{`Show ${showBalances ? 'less' : 'more'}`}</p>
              <img
                src={showBalances ? Icons.roundedGrayMinusIcon : Icons.roundedGrayPlusIcon}
                alt="icon"
                className="ml-2"
              />
            </div>
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <div className="flex flex-row justify-start items-center">
              <p className="font-normal text-[12px] text-dGray-600">Available credit</p>
              <ExtendedTooltip
                trigger={
                  <img
                    src={isHovered === AVAILABLECREDIT ? Icons.helpBoldIcon : Icons.helpIcon}
                    className="cursor-pointer ml-2"
                    width={13.5}
                    onMouseEnter={() => setIsHovered(AVAILABLECREDIT)}
                    onMouseLeave={() => setIsHovered('')}
                  />
                }
                title={'Available credit'}
                content="The amount you can currently spend on your credit card. It's calculated as your total credit limit minus your current balance and any pending charges not yet posted to your account"
              />
            </div>
            <p className="font-semibold text-xl mt-1 text-dGray-900" data-testid="balance-field">
              {transformAmount(summaryData.availableCredit)}
            </p>
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <p className="font-normal text-[12px] text-dGray-600">Credit line</p>
            <p className="font-semibold text-xl mt-1 text-dGray-900" data-testid="balance-field">
              {transformAmount(summaryData.creditLimit)}
            </p>
          </div>
          <div className={`${showBalances ? 'flex mt-4' : 'hidden'}  md:flex flex-col justify-start`}>
            <p className="font-normal text-[12px] text-dGray-600">Last statement</p>
            <p className="font-semibold text-xl mt-1 text-dGray-900" data-testid="balance-field">
              {transformAmount(summaryData.lastStatement)}
            </p>
          </div>
          <div className="w-full md:w-auto flex flex-col justify-start">
            <Link to="/payments" className="w-full mt-8 md:mt-0">
              <Button className="w-full bg-brand-600 hover:bg-brand-700 text-sm text-white">Make Payment</Button>
            </Link>
            <p className="font-medium text-[12px] text-dGray-700 mt-2 md:text-end">
              {transformAmount(summaryData.currentDue)} due at this time
            </p>
            <AutoPayIndicator />
          </div>
        </div>
      </div>
    </div>
  )
}

AccountSummary.propTypes = {
  data: PropTypes.object,
}

export default AccountSummary
