/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-refresh/only-export-components */

import { ROUTE_SIGN_IN } from '@/routes/paths.js'
import { createContext, useCallback, useContext, useEffect, useState } from 'react'
import { fetchAuthSession, fetchUserAttributes, signOut } from 'aws-amplify/auth'
import { getCurrentUserSessionAccessToken, getUserDetails, removeUserDetails, saveUserDetails } from '@/storage'

import { END_POINTS } from '@/api/end-points'
import { Hub } from 'aws-amplify/utils'
import PropTypes from 'prop-types'
import { User } from '../classes/User'
import fetch from '@/api/fetch-with-authorization'

const AuthContext = createContext()

const AuthProvider = ({ children, client }) => {
  const userInstance = new User(getUserDetails())
  const [user, setUserState] = useState(userInstance)
  const [accessToken, setAccessToken] = useState(getCurrentUserSessionAccessToken())
  const [loading, setLoading] = useState(false)
  const [isCardApplicationSubmitted, setIsCardApplicationSubmitted] = useState(false)
  const [isTokenRefreshing, setIsTokenRefreshing] = useState(false)
  const authenticated = !!accessToken

  /**
   * Sets the user state with a new user instance and saves the user details to localStorage.
   *
   * @param {Object} newUserData - The new user data to update.
   */
  const setUser = newUserData => {
    setUserState(new User(newUserData))
    saveUserDetails(newUserData)
  }

  async function logout() {
    removeUserDetails()

    await signOut()
  }

  const refreshUserSession = useCallback(
    async ({ force = false } = {}) => {
      const session = await fetchAuthSession({ forceRefresh: force })
      setAccessToken(session.tokens?.accessToken.toString())
      setIsTokenRefreshing(false)
    },
    [isTokenRefreshing]
  )

  //NOTE:setIsCardApplicationSubmitted need any implementation
  const handleRoutes = useCallback(
    customerData => {
      if (customerData) {
        setIsCardApplicationSubmitted(true)
        // const isBannerDismissed = customerData.preferences.statusBannerDismissed ?? false
        // console.log('handleNavigation: isBannerDismissed =', isBannerDismissed)
      }
    },

    [isCardApplicationSubmitted, user]
  )

  useEffect(() => {
    const fetchCustomerData = async () => {
      const { customer } = await client.ensureQueryData({
        queryKey: ['me'],
        queryFn: () => fetch({ path: END_POINTS.ME }),
      })
      return customer
    }

    const setUserContext = async () => {
      try {
        const user = await fetchUserAttributes()
        const { tokens } = await fetchAuthSession()
        const customer = await fetchCustomerData()
        setUser({ ...user, customer })
        handleRoutes(customer)
        setAccessToken(tokens.accessToken.toString())
      } catch (err) {
        console.log(err)
      } finally {
        setLoading(false)
      }
    }

    // listen to amplify auth events
    const unsubscribe = Hub.listen('auth', async ({ payload }) => {
      switch (payload.event) {
        case 'signedIn':
          console.log('user have been signedIn successfully.')
          await setUserContext()
          break

        case 'signedOut':
          console.log('user have been signedOut successfully.')
          client.clear()
          window.location.href = ROUTE_SIGN_IN
          break

        case 'tokenRefresh':
          console.log('auth tokens have been refreshed.')
          setIsTokenRefreshing(false)
          break

        case 'tokenRefresh_failure':
          console.log('failure while refreshing auth tokens.')
          // NOTE: Not working due to many re-rendering
          // setIsTokenRefreshing(false)
          break

        case 'signInWithRedirect':
          console.log('signInWithRedirect API has successfully been resolved.')
          // To continue loading util user is redirected to the next page
          setLoading(true)
          await setUserContext()
          break

        case 'signInWithRedirect_failure':
          console.log('failure while trying to resolve signInWithRedirect API.')
          break

        case 'customOAuthState':
          console.log('custom state returned from CognitoHosted UI')
          break
      }
    })
    return () => unsubscribe()
  }, [])

  return (
    <AuthContext.Provider
      value={{
        user,
        logout,
        setUser,
        client,
        loading,
        setLoading,
        accessToken,
        isTokenRefreshing,
        setIsTokenRefreshing,
        authenticated,
        refreshUserSession,
        isCardApplicationSubmitted,
        setIsCardApplicationSubmitted,
      }}
    >
      {children}
    </AuthContext.Provider>
  )
}

AuthProvider.propTypes = {
  children: PropTypes.element,
  client: PropTypes.shape({
    clear: PropTypes.func.isRequired,
    ensureQueryData: PropTypes.func.isRequired,
  }).isRequired,
}

const useAuth = () => {
  const context = useContext(AuthContext)
  if (!context) {
    throw new Error('useAuth must be used within a AuthProvider')
  }
  return context
}

export { AuthProvider, useAuth }
