import PropTypes from 'prop-types'
import { Icons } from '@/theme/Images'
import { toast } from 'sonner'
import FailureAlertIcon from '../assets/icons/failure-alert-icon'

const ToastMessage = ({ text, description, success, dismissible = true }) => {
  return (
    <div className="p-4 flex flex-row rounded-lg border-[1px] border-dGray-300 md:w-[400px] w-full justify-between items-start">
      <div className="flex flex-row items-start">
        {success ? (
          <div className="mt-[-6px] ml-[-8px]">
            <Icons.SuccessToastIcon />
          </div>
        ) : (
          <div className="mt-[-6px] ml-[-8px]">
            <FailureAlertIcon />
          </div>
        )}
        <div className="flex flex-col justify-center items-start ml-3">
          {success ? (
            <>
              <p className="font-semibold text-sm text-dGray-900 mb-1 font-sans">{text}</p>
              <p className="font-normal text-sm text-dGray-600 font-sans">{description}</p>
            </>
          ) : (
            <>
              {' '}
              <p className="font-semibold text-sm text-dGray-700 mb-1 font-sans">{text}</p>
              <p className="font-normal text-sm text-dGray-600 font-sans">{description}</p>
              {dismissible ? (
                <div className="flex flex-row justify-start mt-2">
                  <button
                    className="font-semibold text-sm text-left font-sans mr-1 py-1 rounded-md text-dGray-600"
                    onClick={() => toast.dismiss()}
                  >
                    Dismiss
                  </button>
                  <button className=" font-sans font-inter font-semibold text-sm leading-5 text-left py-1 px-2 rounded-md text-[--brand-700]">
                    Contact Support
                  </button>
                </div>
              ) : null}
            </>
          )}
        </div>
      </div>
      <img onClick={() => toast.dismiss()} src={Icons.closeIcon} className="cursor-pointer" alt="Close" />
    </div>
  )
}

ToastMessage.propTypes = {
  text: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  success: PropTypes.bool.isRequired,
  dismissible: PropTypes.bool,
}

export { ToastMessage }
